<template>
  <div class="tab_one">
    <div class="data_box">
      <!-- 温度 规则 更新时间 -->
      <div class="temp_time">
        <div class="left_box">
          <div class="temp">环境温度：{{ envTemp ? `${envTemp}℃` : "--" }}</div>

          <template v-if="deviceType === 1">
            <div class="rule_title">控制规则：</div>

            <el-popover
              placement="bottom-start"
              trigger="hover"
              v-for="(t, idx) in channelList"
              :key="idx"
              style="margin-right: 24px"
            >
              <template #reference>
                <div class="rule" :class="{ use: t.channel_status }">
                  {{ t.remark }}
                </div>
              </template>
              <div class="pop_container">
                <div
                  class="item"
                  v-for="(rule, ridx) in t.control_auto_array"
                  :key="ridx * 34"
                >
                  <div class="title">
                    <div class="name">{{ rule.remark }}</div>
                    <div class="run" v-if="rule.is_run">(规则运行中)</div>
                  </div>
                  <div class="desc_item mt17">周期：{{ rule.week_day | weekFmt }}</div>
                  <div class="desc_item">
                    {{ t.task_type | fontFmt }}时间：{{
                      rule.time_start.substring(0, 5)
                    }}-{{ rule.time_end.substring(0, 5) }}
                  </div>
                </div>
              </div>
            </el-popover>
          </template>

          <!-- <div class="rule use">二号楼补水</div> -->
        </div>
        <div class="right_box">
          <img
            @click="refresh"
            src="../../../assets/refresh2.png"
            :class="{ refresh: isRefresh }"
            alt=""
          />
          <div class="time">更新时间：{{ timeUpdate || "--" }}</div>
        </div>
      </div>

      <!-- 水箱 水泵 -->
      <div class="water_pump">
        <div class="water_box" :class="online ? '' : 'c3'">
          <div class="water_box_container">
            <div class="title">水箱</div>
            <div class="item" v-for="box in boxList" :key="box.id">
              <div class="fr4">
                <div class="icon">
                  <img v-if="online" src="../../../assets/home_icon_1.png" alt="" />
                  <img v-else src="../../../assets/home_icon_4.png" alt="" />
                </div>
                <div class="name">{{ box.name }}</div>
                <div class="run_box">
                  <div class="run" v-if="box.is_valve_open">补水中</div>
                  <template v-for="(mac, midx) in box.machine_array">
                    <div class="run" :key="midx" v-if="mac.is_heating">
                      {{ mac.name }}加热中
                    </div>
                  </template>
                </div>
              </div>
              <div class="fr3">
                <div class="desc">水位(%)</div>
                <div class="val">{{ box.level_per === null ? "-" : box.level_per }}</div>
              </div>
              <div class="fr3">
                <div class="desc">水温(℃)</div>
                <div class="val">{{ box.temp_data === null ? "-" : box.temp_data }}</div>
              </div>
            </div>
            <div class="empty" v-for="i in empty1" :key="i"></div>
          </div>
        </div>
        <div class="water_box" :class="online ? 'c2' : 'c3'">
          <div class="water_box_container">
            <div class="title">水泵</div>
            <div class="item" v-for="(pipe, pdx) in pipeList" :key="pdx * 1002">
              <div class="fr4">
                <div class="icon">
                  <template v-if="pipe.pump_type">
                    <img v-if="online" src="../../../assets/home_icon_3.png" alt="" />
                    <img v-else src="../../../assets/home_icon_6.png" alt="" />
                  </template>
                  <template v-else>
                    <img v-if="online" src="../../../assets/home_icon_2.png" alt="" />
                    <img v-else src="../../../assets/home_icon_5.png" alt="" />
                  </template>
                </div>
                <div class="name">{{ pipe.name }}</div>
                <div class="run_box">
                  <template v-if="pipe.is_water_pump">
                    <div v-if="pipe.pump_type" class="run">循环中</div>
                    <div v-else class="run">供水中</div>
                  </template>
                </div>
              </div>
              <div class="fr3">
                <div class="desc">电流(A)</div>
                <div class="val">
                  {{ pipe.water_pump_data === null ? "-" : pipe.water_pump_data }}
                </div>
              </div>
              <div class="fr3">
                <div class="desc">回水温(℃)</div>
                <div class="val">
                  {{ pipe.back_water_data === null ? "-" : pipe.back_water_data }}
                </div>
              </div>
            </div>
            <div class="empty" v-for="i in empty2" :key="i"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 历史数据 -->
    <HistoryData :id_device="id_device" ref="historyDataRef" />

    <!-- 机组时长 -->
    <CrewTime :id_device="id_device" ref="crewRef" />
  </div>
</template>

<script>
import { GetHomeDataApi } from "@/api";
import CrewTime from "./tab_one_component/crew_time.vue";
import HistoryData from "./tab_one_component/history_data.vue";

export default {
  props: ["id_device"],
  components: { CrewTime, HistoryData },
  data() {
    return {
      // 环境温度
      envTemp: "",
      // 通道
      channelList: [],
      // 更新时间
      timeUpdate: "",
      // 水箱
      boxList: [],
      // 管道
      pipeList: [],
      // 水箱 水泵是否在线
      online: false,
      // 左边补空
      empty1: 0,
      // 右边补空
      empty2: 0,
      // 是否刷新
      isRefresh: false,
    };
  },
  methods: {
    // 父组件触发 切换了设备
    faRefresh() {
      setTimeout(() => {
        this.$refs.historyDataRef.faRefresh();
        this.$refs.crewRef.faRefresh();
        this.getHomeData();
      }, 100);
    },

    refreshData() {
      this.getHomeData();
    },

    // 刷新
    async refresh() {
      this.isRefresh = true;
      this.getHomeData();
      setTimeout(() => {
        this.isRefresh = false;
      }, 300);
    },

    // 获取首页顶部四个色块数据
    async getHomeData() {
      const params = {
        id_device: this.id_device,
      };
      const { ret, data, msg } = await GetHomeDataApi(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.envTemp = data.env_temp;
      this.channelList = data.channel_array;
      this.timeUpdate = data.time_update;
      this.boxList = data.box_array;
      this.pipeList = data.pipe_array;
      this.online = data.online;
      const len1 = data.box_array.length;
      const len2 = data.pipe_array.length;
      const empty = Math.abs(len1 - len2);
      if (len1 > len2) {
        this.empty2 = empty;
        this.empty1 = 0;
      } else {
        this.empty1 = empty;
        this.empty2 = 0;
      }
    },
  },

  computed: {
    isRun() {
      return this.tempAndPres.some((i) => i.is_water_pump);
    },
    deviceType() {
      return this.$store.state.device.deviceType;
    },
  },
  mounted() {
    this.getHomeData();
  },
  filters: {
    fontFmt(v) {
      switch (v) {
        case 10:
          return "产水";
        case 11:
          return "补水";
        case 12:
          return "回水";
        case 13:
          return "供水";
        case 14:
          return "循环";
      }
    },
    weekFmt(v) {
      const weeks = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
      const str = [];
      for (let i = 0; i < 6; i++) {
        if (v & Math.pow(2, i)) {
          str.push(weeks[i]);
        }
      }
      return str.length === 7 ? "每天" : str.join("、");
    },
  },
};
</script>

<style lang="scss" scoped>
.data_box {
  background: #fff;
  padding: 0 24px 24px;

  .temp_time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;

    .left_box {
      flex: 1;
      display: flex;
      align-items: center;

      .temp {
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 400;
        font-size: 14px;
        color: #3d3d3d;
        line-height: 14px;
      }

      .rule_title {
        @extend .temp;
        margin: 0 12px 0 32px;
      }

      .rule {
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        border-bottom: 1px solid #999;
        cursor: pointer;

        &.use {
          color: #00b578;
          border-color: #00b578;
        }
      }
    }

    .right_box {
      display: flex;
      align-items: center;

      @keyframes rotate {
        0% {
        }
        100% {
          transform: rotate(360deg);
        }
      }

      img {
        width: 16px;
        height: 16px;
        cursor: pointer;

        &.refresh {
          animation: rotate 0.4s linear;
        }
      }

      .time {
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 400;
        font-size: 14px;
        color: #3d3d3d;
        margin-left: 6px;
      }
    }
  }

  .water_pump {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;

    .water_box {
      background: #00b578;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding-top: 4px;

      &.c2 {
        background-color: #3662ec;
      }

      &.c3 {
        background: #999999;
      }

      .water_box_container {
        background-color: #fff;
        border-radius: 12px;
        padding: 0 16px 16px;

        & > div:nth-child(n + 3) {
          margin-top: 12px;
        }

        .title {
          height: 56px;
          display: flex;
          align-items: center;
          padding-left: 16px;
        }

        .item {
          height: 92px;
          background: #f6fafd;
          border-radius: 8px;
          display: flex;
          padding-left: 24px;

          .fr4 {
            flex: 0 0 50%;
            display: flex;
            align-items: center;

            .icon {
              width: 24px;
              height: 24px;

              img {
                width: inherit;
                height: inherit;
              }
            }

            .name {
              font-family: Source Han Sans, Source Han Sans;
              font-weight: 400;
              font-size: 14px;
              color: #3d3d3d;
              margin-left: 8px;
            }

            .run_box {
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .run {
                height: 20x;
                line-height: 20px;
                padding: 0 4px;
                background: rgba(255, 143, 31, 0.1);
                border-radius: 2px 2px 2px 2px;
                font-family: Source Han Sans, Source Han Sans;
                font-weight: 400;
                font-size: 12px;
                color: #ff8f1f;

                &:nth-child(n + 2) {
                  margin-top: 4px;
                }
              }
            }
          }

          .fr3 {
            flex: 0 0 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .desc {
              font-family: Source Han Sans, Source Han Sans;
              font-weight: 400;
              font-size: 14px;
              color: #999999;
            }

            .val {
              font-family: Source Han Sans, Source Han Sans;
              font-weight: 700;
              font-size: 18px;
              color: #3d3d3d;
              margin-top: 12px;

              &.c2 {
                color: #ff5757;
              }
            }
          }
        }

        .empty {
          height: 92px;
        }
      }
    }
  }
}

.pop_container {
  .item {
    &:nth-child(n + 2) {
      margin-top: 24px;
    }

    .title {
      display: flex;
      align-items: center;
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 700;
      font-size: 14px;
      .name {
        color: #3d3d3d;
      }

      .run {
        color: #ff8f1f;
        margin-left: 12px;
      }
    }

    .desc_item {
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 400;
      font-size: 14px;
      color: #3d3d3d;
      margin-top: 12px;

      &.mt17 {
        margin-top: 17px;
      }
    }
  }
}
</style>
