<template>
  <div class="main">
    <!-- 系统选择器 -->
    <div class="system_select">
      <div class="left_box">
        <span>选择系统</span>
        <el-select v-model="id_device" placeholder="请选择系统" @change="selectedDevice">
          <el-option
            v-for="item in deviceList"
            :key="item.id_device"
            :label="item.name"
            :value="item.id_device"
          >
          </el-option>
        </el-select>
      </div>

      <div class="right_box">
        <span>{{ deviceName }}</span>
      </div>
    </div>

    <el-scrollbar class="scroll_box">
      <!-- 系统内容 -->
      <div class="content_box">
        <div class="header_box">
          <div class="header_left">
            <div class="bluetooth">
              <img v-if="deviceType === 1" src="../../../assets/bt.png" alt="" />
            </div>
            <div :class="tab === 1 ? 'current' : 'tab'" @click="changeTab(1)">
              数据监测
            </div>
            <div :class="tab === 2 ? 'current' : 'tab'" @click="changeTab(2)">
              运维分析
            </div>
            <div :class="tab === 3 ? 'current' : 'tab'" @click="changeTab(3)">
              远程调控
            </div>

            <div :class="tab === 4 ? 'current' : 'tab'" @click="changeTab(4)">
              检测管理
            </div>
          </div>
          <div class="header_right" v-if="getAdminInfo.length">
            <span>管理员:</span>
            <el-tooltip
              class="item"
              effect="light"
              :content="'联系方式:' + item.tel"
              placement="top"
              v-for="item in getAdminInfo"
              :key="item.id"
            >
              <span class="pop_font"> {{ item.real_name }}</span>
            </el-tooltip>
          </div>
        </div>

        <!-- 动态加载四个子项 -->
        <Component
          :id_device="id_device"
          ref="tabRef"
          :is="getComponent"
          :deviceName="deviceName"
        />
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { GetConfigSystem } from "@/api";
import TabOne from "./tab_one.vue";
import TabTwo from "./tab_two.vue";
import TabThree from "./tab_three.vue";
import TabFour from "./tab_four.vue";

export default {
  name: "system_info",
  data() {
    return {
      // 外面带进来的项目
      id_project: "",
      // 当前用户的角色 100 === superAdmin
      role: "",
      // 当前所在的tab
      tab: 1,
      // 设备列表
      deviceList: [],
      // 包含设备号 管理员名称 管理员电话
      // deviceInfoList: [],
      // 当前选中的设备
      id_device: "",
      // 当前系统版本 1蓝牙版 显示蓝牙图标
      deviceType: "",
    };
  },
  methods: {
    // 切换tab
    changeTab(tab) {
      if (this.tab === tab) return;
      this.tab = tab;
    },

    // 获取项目信息
    async getSysList() {
      const params = {
        id_project: this.id_project,
        custom_desc: 1,
      };
      const { ret, data, msg } = await GetConfigSystem(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.deviceList = data.data;
    },

    // 选中设备
    selectedDevice() {
      const index = this.deviceList.findIndex((i) => i.id_device === this.id_device);
      const type = this.deviceList[index].heater_device_type;
      const idProject = this.deviceList[index].id_project;
      this.$store.commit("device/save", ["id_device", this.id_device]);
      this.$store.commit("device/save", ["deviceType", type]);
      this.$store.commit("device/save", ["id_project", idProject]);
      this.deviceType = type;
      this.$nextTick(() => {
        this.$refs.tabRef.faRefresh();
      });
    },
  },
  created() {
    this.id_project = this.$route.query.p / 1;
    this.id_device = this.$route.query.c;
    this.deviceType = this.$route.query.tp / 1;
    this.$store.commit("device/save", ["id_device", this.id_device]);
    this.$store.commit("device/save", ["deviceType", this.deviceType]);
    this.$store.commit("device/save", ["id_project", this.id_project]);
  },

  computed: {
    // 动态渲染组件
    getComponent() {
      if (this.tab === 1) {
        return TabOne;
      } else if (this.tab === 2) {
        return TabTwo;
      } else if (this.tab === 3) {
        return TabThree;
      } else {
        return TabFour;
      }
    },

    // 获取设备的管理员信息
    getAdminInfo() {
      if (this.id_device) {
        const current = this.deviceList.filter((i) => {
          return i.id_device === this.id_device;
        });
        if (current.length) {
          return current[0].admin;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },

    // 动态计算系统名称
    deviceName() {
      if (this.id_device) {
        const index = this.deviceList.findIndex((i) => i.id_device === this.id_device);
        if (index === -1) return "请选择系统";
        return this.deviceList[index].name;
      } else {
        return "请选择系统";
      }
    },
  },

  beforeDestroy() {
    this.$store.commit("device/saveDevice", "");
  },

  watch: {
    id_device(new1, old) {
      this.getSysList();
    },
  },
};
</script>

<style lang="scss" scoped>
.system_select {
  height: 42px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_box {
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
      font-size: 14px;
      color: #1e1e1e;
    }
  }

  .right_box {
    margin-right: 8px;
    font-size: 14px;
    color: #1e1e1e;

    span {
      font-weight: bold;
    }
  }
}

.scroll_box {
  width: 100%;
  height: calc(100vh - 170px);

  .content_box {
    margin-top: 12px;
    height: auto;
    margin-left: 12px;
    border-radius: $radius;

    .header_box {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #eff2f7;
      background-color: #fff;

      .header_left {
        height: 100%;
        display: flex;

        .bluetooth {
          padding: 0 30px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
          }
        }

        .tab {
          height: 100%;
          line-height: 46px;
          margin-right: 63px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }

        .current {
          @extend .tab;
          position: relative;
          color: #2a47b0;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 65px;
            height: 4px;
            background: #2a47b0;
          }
        }
      }

      .header_right {
        padding-right: 15px;

        .pop_font {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          cursor: default;
          margin-left: 6px;
        }
      }
    }
  }
}
</style>

<style lang="scss"></style>
