<template>
  <div class="crew_time_component">
    <div class="header">
      <span>机组工作时长</span>
    </div>

    <div class="select_box">
      <div class="day_box">
        <div class="label">选择日期:</div>
        <el-date-picker
          class="date1"
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="dateChange"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <div class="right_box">
        <span
          :class="dateType === 1 ? 'current' : ''"
          @click="changeType(1)"
        >最近7天</span>
        <span
          :class="dateType === 2 ? 'current' : ''"
          @click="changeType(2)"
        >最近30天</span>
      </div>
    </div>

    <div class="chart_box">
      <div
        id="chart3"
        style="width: 100%; height: 100%"
      ></div>
    </div>

    <div class="total_box">
      <div
        class="item"
        v-for="item in totalList"
        :key="item.id"
      >
        <div class="circle"></div>
        <span>{{ item.name }}{{ item.total }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetWorkTime } from '@/api'
import DateFmt from '@/utils/DateFmt.js'

export default {
  props: ['id_device'],
  data() {
    return {
      date: [],
      // echart 实例
      crewChart: null,
      // 时间类型 1最近7天 2最近30天 3自选时间
      dateType: 1,
      // 统计列表
      totalList: []
    }
  },
  methods: {
    faRefresh() {
      this.getWorkTime()
    },
    // 获取工作时长
    async getWorkTime() {
      const params = {
        id_device: this.id_device,
        time_start: this.date[0] + ' 00:00:00',
        time_end: this.date[1] + ' 23:59:59'
      }
      const { ret, data, msg } = await GetWorkTime(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      const xData = []
      const yData = {}
      const axisLabel = []
      // 统计
      const totalList = []
      data.map((i, idx) => {
        const temp = []
        const totalTemp = {
          id: idx,
          name: `${i.name}工作总时长: `
        }
        let total = 0
        axisLabel.push(i.name)
        i.list.map((j) => {
          // 统计总时长
          total += j.total_machine_time

          // 时间, 通用x坐标轴
          if (idx === 0) {
            xData.push(j.time_create)
          }
          // 数据
          temp.push(parseInt(j.total_machine_time / 60))
        })
        yData['data' + idx] = temp
        totalTemp.total = (total / 3600).toFixed(2) + 'h'
        totalList.push(totalTemp)
        this.totalList = totalList
      })
      this.$nextTick(() => {
        this.renderChart(xData, yData, axisLabel)
      })
    },

    // 快捷切换日期
    changeType(t) {
      this.dateType = t
      if (t === 1) {
        this.date = [
          new DateFmt().getDiffTime(-6, 'yyyy-MM-dd'),
          new DateFmt().format('yyyy-MM-dd')
        ]
      } else {
        this.date = [
          new DateFmt().getDiffTime(-29, 'yyyy-MM-dd'),
          new DateFmt().format('yyyy-MM-dd')
        ]
      }
      this.getWorkTime()
    },

    // 手动选择了时间
    dateChange() {
      this.dateType = 3
      this.getWorkTime()
    },

    // 渲染echarts图
    renderChart(xAxis, yAxis, axisLabel) {
      if (!this.crewChart) {
        this.crewChart = echarts.init(document.getElementById('chart3'))
      }

      const series = []
      const colors = ['#f8d072', '#f89e72', '#727bf8', 'blue']
      Object.keys(yAxis).map((k, x) => {
        series.push({
          name: axisLabel[x],
          type: 'bar',
          data: yAxis[k],
          itemStyle: {
            color: colors[x],
            borderRadius: 4
          }
        })
      })

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: xAxis,
            axisTick: {
              alignWithLabel: true,
              show: false
            },
            axisLine: {
              show: false
            }
          }
        ],
        grid: {
          top: '10%',
          left: '5%',
          right: '5%',
          bottom: '5%'
        },
        yAxis: {
          name: '分钟',
          type: 'value'
        },
        series
      }
      this.crewChart.setOption(option, true)
    }
  },
  created() {
    this.date = [
      new DateFmt().getDiffTime(-6, 'yyyy-MM-dd'),
      new DateFmt().format('yyyy-MM-dd')
    ]
  },
  mounted() {
    this.getWorkTime()
  }
}
</script>

<style lang='scss' scoped>
.crew_time_component {
  height: 592px;
  background-color: #fff;
  border-radius: $radius;
  margin-top: 12px;

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1b2351;
    padding: 0 16px;
  }

  .select_box {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .day_box {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin: 0 12px 0 24px;
      }

      .date1 {
        width: 230px;
      }
    }

    .right_box {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      padding-right: 16px;

      span {
        margin: 0 16px;
        cursor: pointer;
      }

      .current {
        color: rgb(42, 71, 176);
      }
    }
  }

  .chart_box {
    width: 100%;
    height: 460px;
    margin-top: 12px;
    display: flex;
    justify-content: center;

    #chart3 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .total_box {
    width: 100%;
    background-color: #fff;
    padding: 24px 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #333;

    .item {
      display: flex;
      align-items: center;
      margin: 0 24px;

      .circle {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-right: 6px;

        &:nth-child(1) {
          background-color: #f8d072;
        }

        &:nth-child(2) {
          background-color: #f89e72;
        }

        &:nth-child(3) {
          background-color: #727bf8;
        }

        &:nth-child(n + 4) {
          background-color: blue;
        }
      }
    }
  }
}
</style>
