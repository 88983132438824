<template>
  <div class="operate_record">
    <!-- 选择器 -->
    <div class="select_box">
      <div class="input_box mr12 mt12">
        <div class="label">操作类型</div>
        <el-select
          v-model="id_type"
          placeholder="请选择"
        >
          <el-option
            v-for="ite in portList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>
      </div>

      <div class="input_box mr12 mt12">
        <div class="label">动作</div>
        <el-select
          v-model="type"
          placeholder="请选择"
        >
          <el-option
            v-for="ite in typeList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>
      </div>

      <div class="input_box mr12 mt12">
        <div class="label">操作</div>
        <el-select
          v-model="action_type"
          placeholder="请选择"
        >
          <el-option
            v-for="ite in actionTypeList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>
      </div>

      <div class="date_box mr12 mt12">
        <div class="label">操作日期</div>
        <el-date-picker
          class="date-picker"
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :clearable="false"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>

      <div class="btn_box mr12 mt12">
        <el-button
          type="primary"
          size="small"
          @click="search"
        >查询</el-button>
        <el-button
          size="small"
          type="primary"
          plain
          @click="reset"
        >重置</el-button>
        <el-button
          size="small"
          type="success"
          plain
          @click="exportData"
        >导出</el-button>
      </div>
    </div>

    <!-- 表格 -->
    <div class="tabel_box">
      <el-table
        :data="controlList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb',
          height: '40px'
        }"
        :stripe="true"
        size="small"
      >
        <el-table-column
          prop="crontrol_name"
          label="名称"
        ></el-table-column>
        <el-table-column label="操作类型">
          <template v-slot="scope">
            <span>{{ scope.row.id_type | idTypeFmt }}</span>
          </template>
        </el-table-column>

        <el-table-column label="动作">
          <template v-slot="scope">
            <span>{{ scope.row.type === 1 ? '手动' : '自动' }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template v-slot="scope">
            <span>{{ scope.row.action_type === 1 ? '开启' : '关闭' }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="操作时间"
          prop="time_create"
        ></el-table-column>
      </el-table>
    </div>

    <!--分页-->
    <div class="pagination_box">
      <el-pagination
        @size-change="sizeChange"
        @current-change="CurrentChange"
        :current-page="page_id + 1"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="page_num"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <ExportExcel ref="exportExcelRef" />
  </div>
</template>

<script>
import { GetControlList } from '@/api'
import DateFmt from '@/utils/DateFmt.js'
import ExportExcel from '@/components/export_excel/export_excel.vue'

const portList = [
  { id: 10, name: '机组' },
  { id: 11, name: '补水阀' },
  { id: 12, name: '回水阀' },
  { id: 13, name: '供水泵' }
]
const typeList = [
  {
    id: 0,
    name: '自动'
  },
  {
    id: 1,
    name: '手动'
  }
]
const actionTypeList = [
  { id: 0, name: '关闭' },
  { id: 1, name: '开启' }
]
export default {
  components: { ExportExcel },
  data() {
    return {
      id_device: '',
      page_id: 0,
      page_num: 20,
      total: 0,
      // 操作端口
      portList,
      // 端口选择
      id_type: '',
      typeList,
      // 关闭还是开启
      type: '',
      actionTypeList,
      action_type: '',
      date: [],
      // 记录
      controlList: [],
      // 快捷时间选择器
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  methods: {
    // 父组件触发的刷新
    faRefresh() {
      this.id_device = this.$store.getters.id_device
      this.getControlList()
    },
    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s
      this.getControlList()
    },
    // 页码变化
    CurrentChange(p) {
      this.page_id = p - 1
      this.getControlList()
    },

    // 查询
    search() {
      this.page_id = 0
      this.getControlList()
    },

    // 重置
    reset() {
      this.page_id = 0
      this.id_type = ''
      this.type = ''
      this.action_type = ''
      this.getControlList()
    },

    // 获取参数
    getParams() {
      const params = {
        id_device: this.id_device,
        time_start: this.date[0] + ' 00:00:00',
        time_end: this.date[1] + ' 23:59:59',
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_type !== '') {
        params.id_type = this.id_type
      }

      if (this.type !== '') {
        params.type = this.type
      }

      if (this.action_type !== '') {
        params.action_type = this.action_type
      }

      return params
    },

    // 获取控制历史记录
    async getControlList() {
      const params = this.getParams()
      const { ret, data, msg } = await GetControlList(params)
      if (ret !== 0) {
        return this.$message.error(msg || '获取操作记录失败!')
      }
      this.total = data.cnt_all
      this.controlList = data.data
    },

    // 导出记录
    exportData() {
      const params = this.getParams()
      this.$refs.exportExcelRef.export({
        name: '操作记录',
        fn: GetControlList,
        params,
        dataMap: ['data'],
        totalMap: ['cnt_all'],
        keyMap: {
          name: '名称',
          id_type: '操作类型',
          action_type: '动作',
          type: '操作',
          time_create: '操作时间'
        },
        valueFmt: {
          id_type(v) {
            switch (v) {
              case 10:
                return '机组'
              case 11:
                return '补水阀'
              case 12:
                return '回水阀'
              case 13:
                return '供水泵'
            }
          },
          action_type(v) {
            return v === 1 ? '手动' : '自动'
          },
          type(v) {
            return v === 1 ? '开启' : '关闭'
          }
        }
      })
    }
  },

  created() {
    this.id_device = this.$store.getters.id_device
    this.date = [
      new DateFmt().getDiffTime(-6, 'yyyy-MM-dd'),
      new DateFmt().format('yyyy-MM-dd')
    ]
    this.getControlList()
  },

  filters: {
    idTypeFmt(v) {
      switch (v) {
        case 10:
          return '机组'
        case 11:
          return '补水阀'
        case 12:
          return '回水阀'
        case 13:
          return '供水泵'
        default:
          return '未配置'
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.tabel_box {
  padding: 0 24px;
  margin-top: 12px;
}
</style>
