<template>
  <el-dialog
    :title="isEdit ? '修改水泵' : '添加水泵'"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="resetForm"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="110px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="水泵类型：">
            <el-select
              v-model="ruleForm.pump_type"
              style="width: 100%"
              :disabled="isEdit"
            >
              <el-option
                v-for="item in [
                  { id: 0, name: '供水泵' },
                  { id: 1, name: '循环泵' },
                ]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="名称：" prop="name">
            <el-input
              v-model.trim="ruleForm.name"
              placeholder="请输入名称"
              maxlength="8"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="回水温度：">
            <el-select
              v-model="ruleForm.back_water_index"
              placeholder="请选择回水温度"
              style="width: 100%"
            >
              <el-option
                v-for="item in backTempature"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="电流：">
            <el-select
              v-model="ruleForm.water_pump_index"
              placeholder="请选择电流"
              style="width: 100%"
            >
              <el-option
                v-for="item in pumpList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <template v-if="!ruleForm.pump_type">
          <el-col :span="12">
            <el-form-item label="压力：">
              <el-select
                v-model="ruleForm.pressure_index"
                placeholder="请选择压力"
                style="width: 100%"
              >
                <el-option
                  v-for="item in pressureList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="回水阀电流：">
              <el-select
                v-model="ruleForm.back_valve_index"
                placeholder="请选择回水阀电流"
                style="width: 100%"
              >
                <el-option
                  v-for="item in valveList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <template v-if="type !== 1">
            <el-col :span="12">
              <el-form-item label="最大量程：" prop="pressure_range">
                <el-input
                  v-model.trim="ruleForm.pressure_range"
                  placeholder="请输入最大量程"
                  maxlength="3"
                  class="unit_mpa"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
        </template>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddProvidePipe, UpdateProvidePipe } from "@/api";

// 生成器
const generator = (str, len) => {
  const arr = Array.from({ length: len }, (_, index) => {
    return {
      id: index,
      name: str + (index + 1),
    };
  });
  return [{ id: -1, name: "未配置" }].concat(arr);
};

const checkPressureRange = (rule, value, cb) => {
  const regExp = /^[0-9]{1}(.[1-9])?$/;
  if (!regExp.test(value)) {
    return cb(new Error("量程输入错误"));
  }
  cb();
};

export default {
  data() {
    return {
      // 1无限版
      type: "",
      // 添加还是修改
      isEdit: false,
      // 设备号
      id_device: "",
      // 是否显示弹窗
      dialogVisible: false,
      // 压力列表
      pressureList: [],
      // 会水温
      backTempature: [],
      // 供水泵
      pumpList: [],
      // 回水阀
      valveList: [],
      // 表单
      ruleForm: {
        id: "",
        // 设备类型
        pump_type: 0,
        // 名称
        name: "",
        // 压力索引
        pressure_index: -1,
        // 回水温索引
        back_water_index: -1,
        // 电流
        water_pump_index: -1,
        // 回水阀电流
        back_valve_index: -1,
        // 最大量程 输入
        pressure_range: "",
      },
      // 校验
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        pressure_range: [
          { required: true, message: "请输入量程", trigger: "blur" },
          { validator: checkPressureRange, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 重置表单
    resetForm() {
      this.pump_type = 0;
      this.ruleForm.pressure_index = -1;
      this.ruleForm.back_water_index = -1;
      this.ruleForm.water_pump_index = -1;
      this.ruleForm.back_valve_index = -1;
      this.$refs.ruleFormRef.resetFields();
    },

    // 添加
    add(device) {
      // ____填充内容____
      this.type = this.$store.state.device.deviceType;
      if (this.type === 1) {
        // 无线版
        this.pressureList = generator("P", 2);
        this.backTempature = generator("T", 4);
        this.pumpList = generator("E", 6);
        this.valveList = generator("E", 6);
      } else {
        // 老版
        this.pressureList = generator("P", 4);
        this.backTempature = generator("T", 8);
        this.pumpList = generator("E", 12);
        this.valveList = generator("E", 12);
      }
      // ____填充内容____
      this.id_device = device;
      this.isEdit = false;
      this.dialogVisible = true;
    },

    // 修改
    edit(info) {
      // ____填充内容____
      this.type = this.$store.state.device.deviceType;
      if (this.type === 1) {
        // 无线版
        this.pressureList = generator("P", 2);
        this.backTempature = generator("T", 4);
        this.pumpList = generator("E", 6);
        this.valveList = generator("E", 6);
      } else {
        // 老版
        this.pressureList = generator("P", 4);
        this.backTempature = generator("T", 8);
        this.pumpList = generator("E", 12);
        this.valveList = generator("E", 12);
      }
      // ____填充内容____
      this.isEdit = true;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.ruleForm.name = info.name;
        this.ruleForm.id = info.id;
        this.ruleForm.pump_type = info.pump_type;
        this.ruleForm.pressure_index =
          info.pressure_index === null ? -1 : info.pressure_index;
        this.ruleForm.back_water_index =
          info.back_water_index === null ? -1 : info.back_water_index;
        this.ruleForm.water_pump_index =
          info.water_pump_index === null ? -1 : info.water_pump_index;
        this.ruleForm.back_valve_index =
          info.back_valve_index === null ? -1 : info.back_valve_index;
        this.ruleForm.pressure_range =
          info.pressure_range === null ? "" : info.pressure_range + "";
      });
    },

    // 确定
    confirm() {
      this.$refs.ruleFormRef.validate((_) => {
        if (!_) return;
        if (this.isEdit) {
          this.editWaterPipe();
        } else {
          this.addWaterPipe();
        }
      });
    },

    // 添加供水
    async addWaterPipe() {
      const form = this.ruleForm;
      const params = {
        id_device: this.id_device,
        pump_type: form.pump_type,
        name: this.ruleForm.name,
      };
      if (form.back_water_index >= 0) {
        params.back_water_index = form.back_water_index;
      }
      if (form.water_pump_index >= 0) {
        params.water_pump_index = form.water_pump_index;
      }
      // 供水
      if (!form.pump_type) {
        if (form.pressure_index >= 0) {
          params.pressure_index = form.pressure_index;
        }
        if (form.back_valve_index >= 0) {
          params.back_valve_index = form.back_valve_index;
        }
      }
      //  f非蓝牙且
      if (form.pump_type === 0 && this.type !== 1) {
        params.pressure_range = parseFloat(form.pressure_range);
      }

      const { ret, data, msg } = await AddProvidePipe(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.$message.success("添加成功");
      this.dialogVisible = false;
      this.$emit("refresh");
    },

    // 编辑水管
    async editWaterPipe() {
      const params = {
        id_pipe: this.ruleForm.id,
        name: this.ruleForm.name,
        pressure_index: this.ruleForm.pressure_index,
        back_water_index: this.ruleForm.back_water_index,
        water_pump_index: this.ruleForm.water_pump_index,
        back_valve_index: this.ruleForm.back_valve_index,
        pressure_range: this.ruleForm.pressure_range,
      };
      const { ret, data, msg } = await UpdateProvidePipe(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.$message.success("更新成功");
      this.dialogVisible = false;
      this.$emit("refresh");
    },
  },
};
</script>

<style lang="scss" scoped></style>
