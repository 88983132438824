<template>
  <div class="tab_two">
    <div class="tab_box">
      <div
        :class="tab === 1 ? 'current' : 'tab'"
        @click="changeTab(1)"
      >
        异常统计
      </div>
      <div
        :class="tab === 2 ? 'current' : 'tab'"
        @click="changeTab(2)"
      >
        操作记录
      </div>
    </div>

    <component
      :is="getComponent"
      ref="tabTwoRef"
    />
  </div>
</template>

<script>
import EarlyWarningTrack from './tab_two_component/early_warning_track.vue'
import OperateRecord from './tab_two_component/operate_record.vue'

export default {
  data() {
    return {
      // 1预警追踪 2操作记录
      tab: 1
    }
  },
  methods: {
    // 父组件触发 切换了设备
    faRefresh() {
      this.$refs.tabTwoRef.faRefresh()
    },
    // 切换tab
    changeTab(tab) {
      if (tab === this.tab) return
      this.tab = tab
    }
  },
  computed: {
    getComponent() {
      if (this.tab === 1) {
        return EarlyWarningTrack
      } else {
        return OperateRecord
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.tab_two {
  min-height: calc(100vh - 170px);
  background-color: #fff;
}

.tab_box {
  height: 60px;
  display: flex;
  align-items: center;

  .tab {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #999999;
    margin: 0 28px;
    cursor: pointer;
  }

  .current {
    @extend .tab;
    color: #1b2351;
  }
}
</style>
