<template>
  <el-dialog
    :title="isEdit ? '编辑水箱' : '添加水箱'"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="resetForm"
  >
    <el-form
      :model="waterCaseForm"
      :rules="rules"
      ref="waterCaseFormRef"
      label-width="100px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="水箱名称：" prop="name">
            <el-input
              v-model="waterCaseForm.name"
              placeholder="请输入水箱名称"
              maxlength="8"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="水箱高度：" prop="vol">
            <el-input
              v-model="waterCaseForm.vol"
              placeholder="请输入水箱高度"
              maxlength="4"
              class="unit_m"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="水箱吨位：" prop="volume">
            <el-input
              v-model="waterCaseForm.volume"
              placeholder="请输入水箱吨位"
              maxlength="4"
              class="unit_t"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="12">
          <el-form-item label="水箱温度：">
            <el-select
              v-model="waterCaseForm.temp_index"
              placeholder="请选择水箱"
              style="width: 100%"
            >
              <el-option
                v-for="item in tempList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="12">
          <el-form-item label="水箱水位：">
            <el-select
              v-model="waterCaseForm.level_index"
              placeholder="请选择水箱"
              style="width: 100%"
            >
              <el-option
                v-for="item in levelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="type !== 1">
          <el-form-item label="最大量程：" prop="level_range">
            <el-input
              v-model="waterCaseForm.level_range"
              placeholder="请输入水箱量程"
              maxlength="4"
              class="unit_m"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="补水阀电流：">
            <el-select
              v-model="waterCaseForm.supply_water_index"
              placeholder="请选择补水阀电流"
              style="width: 100%"
            >
              <el-option
                v-for="item in supplyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddWaterCase, UpdateWaterCase } from "@/api";

// 生成器
const generator = (str, len) => {
  const arr = Array.from({ length: len }, (_, index) => {
    return {
      id: index,
      name: str + (index + 1),
    };
  });
  return [{ id: -1, name: "未配置" }].concat(arr);
};

const checkVol = (rule, value, callback) => {
  const regExp = /^[1-9]{1}[0-9]{0,1}(.[0-9]){0,1}$/;
  if (!regExp.test(value)) {
    return callback(new Error("水箱高度输入错误"));
  }
  callback();
};

const checkVolume = (rule, value, callback) => {
  const regExp = /^[1-9]{1}[0-9]{0,1}(.[0-9])?$/;
  if (!regExp.test(value)) {
    return callback(new Error("水箱吨位输入错误"));
  }
  callback();
};

const checkLevelRange = (rule, value, callback) => {
  const regExp = /^[1-9]{1}[0-9]{0,1}(.[0-9])?$/;
  if (!regExp.test(value)) {
    return callback(new Error("量程输入错误"));
  }
  callback();
};

export default {
  data() {
    return {
      // 是否是编辑
      isEdit: false,
      // 是否显示弹窗
      dialogVisible: false,
      // 类型 1:无线型
      type: "",
      // 设备号
      id_device: "",
      // 水温列表
      tempList: [],
      // 水位列表
      levelList: [],
      // 补水阀电流
      supplyList: generator("E", 12),
      // 表单
      waterCaseForm: {
        id: "",
        // 水箱名称
        name: "",
        // 水箱高度
        vol: "",
        // 水箱吨位
        volume: "",
        // 水温
        temp_index: -1,
        // 水位
        level_index: -1,
        // 量程
        level_range: "",
        // 补水阀电流
        supply_water_index: -1,
      },
      // 规则
      rules: {
        name: [{ required: true, message: "请输入水箱名称", trigger: "blur" }],
        vol: [
          { required: true, message: "请输入水箱高度", trigger: "blur" },
          { validator: checkVol, trigger: "blur" },
        ],
        volume: [
          { required: true, message: "请输入水箱吨位", trigger: "blur" },
          {
            validator: checkVolume,
            trigger: "blur",
          },
        ],
        level_range: [
          { required: true, message: "请输入量程", trigger: "blur" },
          { validator: checkLevelRange, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs.waterCaseFormRef.resetFields();
    },

    // 添加
    add(device) {
      const type = this.$store.state.device.deviceType;
      if (type === 1) {
        // 无线版
        this.tempList = generator("T", 4);
        this.levelList = generator("P", 2);
      } else {
        // 老版
        this.tempList = generator("T", 8);
        this.levelList = generator("L", 4);
      }
      this.type = type;
      this.id_device = device;
      this.isEdit = false;
      this.dialogVisible = true;
    },

    // 编辑
    edit(item) {
      const type = this.$store.state.device.deviceType;
      if (type === 1) {
        // 无线版
        this.tempList = generator("T", 4);
        this.levelList = generator("P", 2);
      } else {
        // 老版
        this.tempList = generator("T", 8);
        this.levelList = generator("L", 4);
      }
      this.type = type;
      this.isEdit = true;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.waterCaseForm.id = item.id;
        this.waterCaseForm.name = item.name;
        this.waterCaseForm.level_index =
          item.level_index === null ? -1 : item.level_index;
        this.waterCaseForm.temp_index = item.temp_index === null ? -1 : item.temp_index;
        this.waterCaseForm.vol = item.vol + "";
        this.waterCaseForm.volume = item.volume + "";
        this.waterCaseForm.level_range = item.level_range + "";
        this.waterCaseForm.supply_water_index =
          item.supply_water_index === null ? -1 : item.supply_water_index;
      });
    },

    // 确定
    confirm() {
      this.$refs.waterCaseFormRef.validate((_) => {
        if (!_) return;
        if (this.isEdit) {
          this.editWaterCase();
        } else {
          this.addWaterCase();
        }
      });
    },

    // 添加水箱
    async addWaterCase() {
      const params = {
        id_device: this.id_device,
        name: this.waterCaseForm.name,
        vol: this.waterCaseForm.vol / 1,
        volume: this.waterCaseForm.volume / 1,
      };
      if (this.waterCaseForm.temp_index >= 0) {
        params.temp_index = this.waterCaseForm.temp_index;
      }
      if (this.waterCaseForm.level_index >= 0) {
        params.level_index = this.waterCaseForm.level_index;
      }
      if (this.type !== 1) {
        params.level_range = this.waterCaseForm.level_range / 1;
      }
      if (this.waterCaseForm.supply_water_index >= 0) {
        params.supply_water_index = this.waterCaseForm.supply_water_index;
      }
      const { ret, data, msg } = await AddWaterCase(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("添加水箱成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },

    // 修改水箱
    async editWaterCase() {
      const params = {
        id_wbox: this.waterCaseForm.id,
        name: this.waterCaseForm.name,
        vol: this.waterCaseForm.vol / 1,
        volume: this.waterCaseForm.volume / 1,
        temp_index: this.waterCaseForm.temp_index,
        level_index: this.waterCaseForm.level_index,
        supply_water_index: this.waterCaseForm.supply_water_index,
      };
      if (this.type !== 1) {
        params.level_range = this.waterCaseForm.level_range / 1;
      }
      const { ret, data, msg } = await UpdateWaterCase(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.$message.success("更新水箱成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
