import { render, staticRenderFns } from "./system_setting.vue?vue&type=template&id=63945198&scoped=true&"
import script from "./system_setting.vue?vue&type=script&lang=js&"
export * from "./system_setting.vue?vue&type=script&lang=js&"
import style0 from "./system_setting.vue?vue&type=style&index=0&id=63945198&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63945198",
  null
  
)

export default component.exports