<template>
  <div class="container">
    <div class="row">
      <div class="desc">每日推送：</div>
      <div class="ctt">
        <div
          class="btn"
          @click="changeIsStart"
          :class="push.is_start ? 'open' : 'close'"
        >
          <span class="open_font">开</span>
          <div class="circle"></div>
          <span class="close_font">关</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="desc">推送时间：</div>
      <div class="ctt">{{ push.push_time || '--' }}</div>
    </div>

    <div class="row">
      <div class="desc">推送方式：</div>
      <div class="ctt">
        <template v-if="push.push_type">
          <span>{{ push.push_type | pushTypeFmt }}</span>
        </template>
        <span v-else>--</span>
      </div>
    </div>

    <div
      class="row"
      v-if="push.push_type & 1"
    >
      <div class="desc">推送手机号：</div>
      <div class="ctt">{{ push.tel | telFmt }}</div>
    </div>

    <div class="edit_btn">
      <el-button @click="showEdit">编辑</el-button>
    </div>

    <EditPush
      ref="editPushRef"
      @refresh="getPush"
    />
  </div>
</template>

<script>
import EditPush from './tab_four_components/edit_push.vue'
import { GetPushSettingApi, UpdatePushApi } from '@/api'

export default {
  components: { EditPush },
  data() {
    return {
      push: {}
    }
  },
  methods: {
    faRefresh() {
      this.getPush()
    },

    // 获取规则
    async getPush() {
      const idDeivce = this.$store.state.device.id_device
      if (!idDeivce) return
      const params = {
        id_device: idDeivce
      }
      const { ret, data, msg } = await GetPushSettingApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.push = data
    },

    // 显示编辑推送的弹窗
    showEdit() {
      this.$refs.editPushRef.open(this.push)
    },

    // 是否开启关闭推送
    async changeIsStart() {
      const isStart = this.push.is_start
      const tip = isStart ? '您确定要关闭推送吗?' : '您确定要开启推送吗?'
      const result = await this.$confirm(tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => 'cancel')
      if (result === 'cancel') return
      const params = {
        id: this.push.id,
        is_start: 1 - isStart
      }
      const { ret, data, msg } = await UpdatePushApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success(isStart ? '关闭成功' : '开启成功')
      this.getPush()
    }
  },
  created() {
    this.getPush()
  },
  filters: {
    pushTypeFmt(v) {
      const lis = []
      if (v & 1) {
        lis.push('短信推送')
      }
      if (v & 2) {
        lis.push('App推送')
      }
      return lis.join('、')
    },

    telFmt(lis) {
      const tel = []
      lis.map(i => {
        tel.push(i.tel)
      })
      return tel.join('、')
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
  min-height: calc(100vh - 224px);
  padding-bottom: 100px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .row {
    width: 500px;
    display: flex;
    align-items: center;
    margin-top: 24px;

    .desc {
      flex: 0 0 100px;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #222222;
      text-align: right;
    }

    .ctt {
      flex: 0 0 4 400px;
      padding-left: 6px;

      .btn {
        width: 42px;
        height: 22px;
        border-radius: 40px 40px 40px 40px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .circle {
          width: 18px;
          height: 18px;
          background: #FFFFFF;
          border-radius: 50%;
        }

        span {
          padding: 0 4px;
          font-size: 12px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .close {
        background: #bfbfbf;

        .open_font {
          display: none;
        }
      }

      .open {
        background: #2a47b0;

        .close_font {
          display: none;
        }
      }
    }
  }

  .edit_btn {
    width: 440px;
    margin-top: 70px;
  }
}
</style>
