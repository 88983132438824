<template>
  <div class="history_data">
    <div class="header">
      <span>历史数据</span>
      <div class="radio_box">
        <div class="radio" @click="changeType(true)">
          <img
            v-if="!isEchart"
            class="radio_img"
            src="../../../../assets/echart.png"
            alt=""
          />
          <img v-else class="radio_img" src="../../../../assets/echart_act.png" alt="" />
        </div>
        <div class="radio" @click="changeType(false)">
          <img
            v-if="isEchart"
            class="radio_img"
            src="../../../../assets/list.png"
            alt=""
          />
          <img v-else class="radio_img" src="../../../../assets/list_act.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 图表模式 -->
    <template v-if="isEchart">
      <div class="ec_select_box">
        <div class="day_box">
          <div class="label">选择日期:</div>
          <el-date-picker
            class="date1"
            v-model="date1"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :clearable="false"
            @change="date1Change"
          >
          </el-date-picker>
        </div>

        <div class="btn">
          <el-button icon="el-icon-setting" type="primary" plain @click="configEchart"
            >曲线设置</el-button
          >
        </div>
      </div>

      <div id="chart9"></div>
      <div class="water_count">水箱用水总量: {{ waterCount }} t</div>
    </template>

    <!-- 列表模式 -->
    <template v-else>
      <div class="li_select_box">
        <div class="day_box">
          <div class="label">选择日期:</div>
          <el-date-picker
            class="date1"
            v-model="date1"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :clearable="false"
          >
          </el-date-picker>
        </div>

        <div class="day_box">
          <div class="label">选择时间:</div>
          <el-time-picker
            class="time_picker"
            is-range
            v-model="date3"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value="HH:mm:ss"
            :clearable="false"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </div>

        <div class="btn">
          <el-button type="primary" @click="searchList">查询</el-button>
          <el-button type="primary" plain @click="resetList">重置</el-button>
          <el-button type="success" plain @click="exportCurrent">导出</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="table_box">
        <!-- tableTitle -->
        <el-table :data="getTableData" style="width: 100%" center>
          <el-table-column
            :prop="ite.prop"
            :label="ite.label"
            v-for="(ite, idx) in tableTitle"
            :key="idx"
          >
            <template v-if="ite.children">
              <el-table-column :label="i.label" v-for="(i, id) in ite.children" :key="id">
                <template v-slot="{ row }">
                  <span>{{ row[i.prop].value }}</span>
                  <span class="point_outline" v-if="!row[i.prop].state">(节点离线)</span>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[10]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </template>

    <!-- 配置echart显示内容 -->
    <EchartConfig ref="echartConfig" @filterData="filterData" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import XLSX from "xlsx";
import { GetHistoryList, GetHistoryData, GetHistoryHeater } from "@/api";
import EchartConfig from "./echart_config.vue";
import DateFmt from "@/utils/DateFmt.js";

export default {
  // 设备号发生变化的时候一定要重置 ids 不然 配置图表内容项会出问题
  props: ["id_device"],
  components: { EchartConfig },
  data() {
    return {
      // 是否是图表模式
      isEchart: true,
      // 日期天 选择器
      date1: "",
      // 配置数据
      configList: [],
      // 数据列表
      data: [],
      // 水量 吨
      waterCount: 0,
      // echart 实例
      echart: null,
      // 配置项返回的渲染id数组
      ids: [],
      // 当前获取数据的设备号
      old_id_device: "",
      // ---------------------------- list --------------------
      // 列表模式参数
      // data1 图表和列表 公用 日期选择器
      date3: null,
      navList: [
        "水箱水位(％)",
        "水箱水温(℃)",
        "回水温(℃)",
        "供水压力(MPa)",
        "补水阀电流(A)",
        "循环泵电流(A)",
        "机组电流(A)",
        "供水泵电流(A)",
        "回水阀电流(A)",
      ],
      // 当前选中的类型, 上传的时候要+1
      type: 0,
      // 表头
      tableTitle: [],
      // 表格数据
      tableData: [],
      // 分页
      page_id: 0,
      page_num: 10,
      total: 0,
    };
  },
  methods: {
    // 父组件触发
    faRefresh() {
      this.getData();
    },
    // 修改类型 图表还是列表
    changeType(isEchart) {
      if (this.isEchart === isEchart) return;
      if (!isEchart) {
        this.echart && this.echart.dispose();
        this.echart = null;
        document.querySelector("#chart9").innerHTML = "";
      }

      this.isEchart = isEchart;
      this.getData();
    },

    // 图表 时间选择器 发生变化
    date1Change() {
      this.ids = [];
      this.getData();
    },

    // 获取数据
    getData() {
      if (this.isEchart) {
        this.getEchartTitle(1);
      } else {
        this.getEchartTitle(2);
      }
    },

    // 获取 echart 可视化数据 标题头
    // 参数 t = 1: 图标模式  t = 2: 列表模式
    async getEchartTitle(t) {
      const params = {
        id_device: this.id_device,
      };
      const { ret, data, msg } = await GetHistoryList(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      // 返回的结果 [{ type: 1, data_array: [{id: 12, name: '水箱C'}, {...}] }]
      this.configList = data;
      const idParams = [];
      data.map((i) => {
        i.data_array.map((j) => {
          idParams.push({
            type: i.type,
            id: j.id,
            title: j.name,
          });
        });
      });
      const sortParams = idParams.sort((a, b) => a.type - b.type);
      // 获取表头对应的id的数据
      if (t === 1) {
        this.getEchartData(sortParams);
      } else {
        this.getTableList(sortParams);
      }
    },

    // 获取数据
    async getEchartData(idParams) {
      const params = {
        id_device: this.id_device,
        time_start: this.date1 + " 00:00:00",
        time_end: this.date1 + " 23:59:59",
        id_type_array: idParams,
      };
      const { ret, data, msg } = await GetHistoryData(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      console.log(data, "dddd");
      this.waterCount = data.sale_water;
      const xData = [];
      const yData = [];
      for (let i = 0; i < idParams.length; i++) {
        yData.push([]);
      }
      data.data.map((i) => {
        xData.push(i.t);
        i.v.map((j, index) => {
          yData[index].push(j);
        });
      });
      this.configRenderData(xData, yData, idParams);
    },

    // 弹窗过滤数据
    filterData(ids) {
      this.ids = ids;
      this.getEchartData(ids);
    },

    // 配置渲染数据 ids: [{id: 1, type: 1}]
    configRenderData(xData, yData, params) {
      const series = [];
      const titles = [
        "水箱水位(％)",
        "水箱水温(℃)",
        "回水温(℃)",
        "供水压力(MPa)",
        "补水阀(A)",
        "循环泵(A)",
        "机组(A)",
        "供水泵(A)",
        "回水阀(A)",
      ];
      const axisIndex = [0, 1, 1, 2, 3, 3, 3, 3, 3];

      params.map((i, idx) => {
        series.push({
          name: i.title + "--" + titles[i.type - 1],
          type: "line",
          symbol: "none",
          yAxisIndex: axisIndex[i.type - 1],
          sampling: "lttb",
          data: yData[idx],
          emphasis: {
            lineStyle: {
              width: 2, // hover时的折线宽度
            },
          },
        });
      });

      const xAxis = {
        type: "category",
        boundaryGap: false,
        data: xData,
      };

      this.$nextTick(() => {
        this.initChart9(xAxis, series);
      });
    },

    // 配置图表按钮点击
    async configEchart() {
      this.$refs.echartConfig.open(this.configList, this.ids);
    },

    // 初始化 图表
    initChart9(xAxis, series) {
      if (!this.echart) {
        this.echart = echarts.init(document.querySelector("#chart9"));
      }
      const option = {
        tooltip: {
          trigger: "axis",
          position: function (point, params, dom, rect, size) {
            const center = size.viewSize[0] / 2; // 宽度的一半
            if (point[0] < center) {
              return [point[0], "10%"];
            } else {
              return [point[0] - size.contentSize[0], "10%"];
            }
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "solid",
            },
          },
          formatter: function (params) {
            let html = `
                <div class="tooltip_box">
                <div class="time">${params[0].name}</div>
              `;
            params.map((i) => {
              html += `
              <div class="item">
                <div class="left_box">
                    <div class="dot" style="background-color:${i.color}"></div>
                    <div class="name">${i.seriesName}</div>
                </div>
                <div class="right_box">${i.value}${
                i.data.state === 0 ? "(节点离线)" : ""
              }</div>
              </div>
              `;
            });
            html += `</div>`;
            return html;
          },
        },
        xAxis,
        yAxis: [
          {
            name: "水位(%)",
            type: "value",
            position: "left",
            min: 0,
            max: 100,
            offset: 30,
            axisLine: {
              show: true,
            },
            nameTextStyle: {
              padding: [0, 0, 0, 50],
            },
            axisTick: {
              show: true,
              inside: true,
            },
            axisLabel: {
              // 刻度线是否槽内
              inside: true,
            },
            splitLine: {
              show: false,
            },
          },
          {
            name: "温度(℃)",
            type: "value",
            position: "left",
            offset: 30,
            min: 0,
            max: 100,
            nameTextStyle: {
              padding: [0, 50, 0, 0],
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
          {
            name: "压力(MPa)",
            type: "value",
            position: "right",
            offset: 30,
            min: 0,
            max: 1.5,
            axisLine: {
              show: true,
            },
            nameTextStyle: {
              padding: [0, 60, 0, 0],
            },
            axisTick: {
              show: true,
              inside: true,
            },
            axisLabel: {
              // 刻度线是否槽内
              inside: true,
            },
            splitLine: {
              show: false,
            },
          },
          {
            name: "电流(A)",
            type: "value",
            position: "right",
            offset: 30,
            min: 0,
            max: 35,
            nameTextStyle: {
              padding: [0, 0, 0, 50],
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
        ],

        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 100,
            top: "5%",
            left: "15%",
            right: "15%",
            bottom: "auto",
          },
        ],
        grid: {
          top: "24%",
          bottom: "8%",
        },
        series,
      };
      this.echart.setOption(option, true);
    },

    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1;
    },

    // 查询
    searchList() {
      this.page_id = 0;
      this.getData();
    },

    // 重置 就是回到今天
    resetList() {
      this.date2 = new DateFmt().format("yyyy-MM-dd");
      this.date3 = ["00:00:00", "23:59:59"];
      this.page_id = 0;
      this.getData();
    },

    // 获取列表数据
    async getTableList(idParams) {
      // 这里先要动态计算出表头格式, 进行渲染 tableTitle
      const tableTitle = [{ label: "监测时间", prop: "t", t: 0 }];
      const typeNames = [...this.navList];

      // 这个参数只针对处理数据, 渲染成 - 生效
      const types = [];

      idParams.map((i, idx) => {
        types.push(i.type);
        if (tableTitle[tableTitle.length - 1].t === i.type) {
          // 说明不是第一次添加
          tableTitle[tableTitle.length - 1].children.push({
            prop: "v" + idx,
            label: i.title,
          });
        } else {
          // 第一次添加
          tableTitle.push({
            label: typeNames[i.type - 1],
            t: i.type,
            children: [
              {
                prop: "v" + idx,
                label: i.title,
              },
            ],
          });
        }
      });
      console.log(tableTitle, "tableTitle");
      this.tableTitle = tableTitle;

      const params = {
        id_device: this.id_device,
        time_start: this.date1 + " " + this.date3[0],
        time_end: this.date1 + " " + this.date3[1],
        id_type_array: idParams,
        is_desc: 1,
      };
      const { ret, data, msg } = await GetHistoryData(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }

      this.total = data.cnt;

      const tableData = [];
      data.data.map((i) => {
        const temp = {
          t: i.t,
        };
        // 对每个时间段的值和时间进行整合成一个数组
        i.v.map((j, jdx) => {
          if (types[jdx] === 2 || types[jdx] === 3) {
            if (j === 99.9) {
              temp["v" + jdx] = "--";
            } else {
              temp["v" + jdx] = j;
            }
          } else {
            if (j === 0) {
              temp["v" + jdx] = "--";
            } else {
              temp["v" + jdx] = j;
            }
          }
        });
        tableData.push(temp);
      });
      this.tableData = tableData;
    },

    // 导出当前数据
    exportCurrent() {
      const firstLine = [];
      this.tableTitle.map((i) => {
        if (i.children && i.children.length) {
          i.children.map((j) => {
            firstLine.push(j.label + "-" + i.label);
          });
        } else {
          firstLine.push(i.label);
        }
      });
      const otherLines = [];
      this.tableData.map((i) => {
        const vLenth = Object.keys(i).length - 1;
        const temp = [i.t];
        for (let k = 0; k < vLenth; k++) {
          temp.push(i["v" + k]);
        }
        otherLines.push(temp);
      });
      // XLSX
      const sheet = [firstLine].concat(otherLines);
      const worksheet = XLSX.utils.aoa_to_sheet(sheet, {
        skipHeader: true,
      });
      // 简单理解为在内存中 创建一个xlsx 文件
      const newWorkBook = XLSX.utils.book_new();
      // 把worksheet添加到workbook中
      XLSX.utils.book_append_sheet(newWorkBook, worksheet, "历史数据");
      // 动态生成名字
      const name = `${this.date1 + " " + this.date3[0]}至${
        this.date1 + " " + this.date3[1]
      }历史数据导出`;
      // 写入文件, CHROME浏览器会直接下载, 后面的是文件名称和后缀
      XLSX.writeFile(newWorkBook, `${name}.xlsx`);
    },
  },

  created() {
    this.date1 = new DateFmt().format("yyyy-MM-dd");
    this.date3 = ["00:00:00", "23:59:59"];
  },
  computed: {
    getTableData() {
      return this.tableData.slice(
        this.page_id * this.page_num,
        this.page_num * (this.page_id + 1)
      );
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.history_data {
  width: 100%;
  height: 790px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 12px;

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1b2351;
    padding: 0 16px;

    .radio_box {
      width: 82px;
      height: 32px;
      border: 1px solid #eff2f7;
      border-radius: 4px;
      display: flex;

      .radio {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:nth-child(1) {
          border-right: 1px solid #eff2f7;
        }

        .radio_img {
          width: 16px;
          height: 18px;
        }
      }
    }
  }

  .ec_select_box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .day_box {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin: 0 12px 0 24px;
      }

      .date1 {
        width: 160px;
      }
    }

    .btn {
      margin-right: 24px;
    }
  }

  .li_select_box {
    display: flex;
    align-items: center;

    .day_box {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin: 0 12px 0 24px;
      }

      .date1 {
        width: 160px;
      }

      .time_picker {
        width: 200px;
      }
    }

    .btn {
      margin-left: 12px;
    }
  }

  .table_box {
    padding: 24px;
  }

  .pages {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  #chart9 {
    width: 100%;
    height: 580px;
    margin: 24px 0 0;
    padding: 0 40px;
    display: flex;
    justify-content: center;
  }

  .water_count {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
}

.point_outline {
  color: #ff5757;
}
</style>
<style lang="scss">
.tooltip_box {
  width: 320px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0;

    .left_box {
      display: flex;
      align-items: center;

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      .name {
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }
}
</style>
