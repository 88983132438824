<template>
  <div class="container">
    <template v-if="deviceType === 1">
      <div class="item" v-for="item in portList" :key="item.id">
        <div class="title_box">
          <div class="left_box">
            <div class="expand" :class="item.show ? 'rotate' : ''">
              <i class="el-icon-arrow-right cursor" @click="changeExpand(item)"></i>
            </div>
            <div class="port_name">C{{ item.channel + 1 }}</div>
            <div class="name">{{ item.remark ? "-" + item.remark : "" }}</div>
            <i
              class="el-icon-edit-outline cursor"
              style="color: #2a47b0"
              @click="openChangeName(item)"
            ></i>
            <div class="state1" v-if="item.channel_status === 1">开启状态</div>
            <div class="state2" v-else>关闭状态</div>
          </div>
          <div class="select">
            <el-select
              v-model="item.task_type"
              placeholder="未配置"
              @change="changeTaskType(item)"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.text"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="btn_box">
            <template v-if="item.task_type >= 10">
              <el-button
                type="warning"
                size="mini"
                v-if="item.channel_status === 1"
                @click="openClose(item, 0)"
                >关闭</el-button
              >
              <el-button v-else type="primary" size="mini" @click="openClose(item, 1)"
                >开启</el-button
              >
            </template>
          </div>
        </div>
        <el-collapse-transition>
          <div class="content_box" v-if="item.show">
            <div class="title">控制规则</div>
            <div class="content">
              <div class="add_box">
                <el-button size="mini" type="primary" @click="showAddRule(item)"
                  >新增控制规则</el-button
                >
              </div>
              <template v-if="item.task.length">
                <div class="rule_box" v-for="it in item.task" :key="it.id">
                  <div class="rule_name_state">
                    <div class="name">{{ it.remark }}</div>
                    <span class="state8" v-if="it.state === 1">已启用</span>
                  </div>
                  <div class="rule_detail">
                    <div class="line">
                      <div class="desc">星期：</div>
                      <div class="result">
                        <span v-if="it.week_day === 127">全天</span>
                        <template v-else>
                          <span class="week" v-if="it.week_day & 1">周一</span>
                          <span class="week" v-if="it.week_day & 2">周二</span>
                          <span class="week" v-if="it.week_day & 4">周三</span>
                          <span class="week" v-if="it.week_day & 8">周四</span>
                          <span class="week" v-if="it.week_day & 16">周五</span>
                          <span class="week" v-if="it.week_day & 32">周六</span>
                          <span class="week" v-if="it.week_day & 64">周日</span>
                        </template>
                      </div>
                    </div>

                    <div class="line">
                      <div class="desc">时间：</div>
                      <div class="result">{{ it.time_start }} - {{ it.time_end }}</div>
                    </div>

                    <template v-if="item.task_type === 10">
                      <div class="line" v-for="i in it.detail_list" :key="i.id">
                        <div class="desc">产水目标温度(T{{ i.data_index + 1 }})：</div>
                        <div class="result">
                          <span class="ot">小于{{ i.preset }}℃开启</span>
                          <span>大于{{ i.target }}℃关闭</span>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.task_type === 11">
                      <div class="line" v-for="i in it.detail_list" :key="i.id">
                        <div class="desc">
                          {{ i.cfg_type ? "目标水位" : "补水目标温度" }}({{
                            i.cfg_type ? "L" : "T"
                          }}{{ i.data_index + 1 }})：
                        </div>
                        <div class="result">
                          <span class="ot"
                            >小于{{ i.preset }}{{ i.cfg_type ? "%" : "℃"
                            }}{{ i.cfg_type ? "开启" : "关闭" }}</span
                          >
                          <span
                            >大于{{ i.target }}{{ i.cfg_type ? "%" : "℃"
                            }}{{ i.cfg_type ? "关闭" : "开启" }}</span
                          >
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.task_type === 12">
                      <div class="line" v-for="i in it.detail_list" :key="i.id">
                        <div class="desc">回水目标温度(T{{ i.data_index + 1 }})：</div>
                        <div class="result">
                          <span class="ot">小于{{ i.preset }}℃开启</span>
                          <span>大于{{ i.target }}℃关闭</span>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.task_type === 13">
                      <div class="line" v-for="i in it.detail_list" :key="i.id">
                        <div class="desc">
                          {{ i.cfg_type ? "水位" : "温度" }}({{ i.cfg_type ? "P" : "T"
                          }}{{ i.data_index + 1 }})：
                        </div>
                        <div class="result">
                          <span
                            >{{ i.cfg_type ? "水位" : "温度" }}{{ i.target }}({{
                              i.cfg_type ? "%" : "℃"
                            }})</span
                          >
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="line" v-for="i in it.detail_list" :key="i.id">
                        <div class="desc">循环目标温度(T{{ i.data_index + 1 }})：</div>
                        <div class="result">
                          <span class="ot">小于{{ i.preset }}℃开启</span>
                          <span>大于{{ i.target }}℃关闭</span>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="operate_box">
                    <el-button
                      v-if="it.state !== 1"
                      type="primary"
                      size="mini"
                      plain
                      @click="useRule(item, it, 1)"
                      >启用</el-button
                    >
                    <el-button
                      v-else
                      type="warning"
                      size="mini"
                      plain
                      @click="useRule(item, it, 2)"
                      >停用</el-button
                    >
                    <el-button size="mini" plain type="primary" @click="delRule(item, it)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </el-collapse-transition>
      </div>
    </template>

    <div class="not_control" v-else>
      <img src="../../../../assets/cant_control.png" alt="" />
      <span>当前系统暂无此功能！</span>
    </div>

    <ChangeName ref="changeNameRef" @refresh="getChannelList" />

    <!-- 机组控制规则 -->
    <CrewRule ref="crewRuleRef" @refresh="changeExpand" />

    <!-- 补水 -->
    <ValveRule ref="valveRef" @refresh="changeExpand" />

    <!-- 供水 -->
    <PumpRule ref="pumpRuleRef" @refresh="changeExpand" />

    <!-- 回水 -->
    <BackWaterRule ref="backWaterRef" @refresh="changeExpand" />

    <!-- 循环 -->
    <LoopRule ref="loopRuleRef" @refresh="changeExpand" />
  </div>
</template>

<script>
import {
  GetChannelListApi,
  GetChannelTaskListApi,
  UpdateChannelApi,
  UpdateChannelTaskApi,
} from "@/api";
import ChangeName from "./system_control_component/change_name.vue";
import { Loading } from "element-ui";
import CrewRule from "./system_control_component/crew_rule.vue";
import ValveRule from "./system_control_component/valve_rule.vue";
import PumpRule from "./system_control_component/pump_rule.vue";
import BackWaterRule from "./system_control_component/back_water_rule.vue";
import LoopRule from "./system_control_component/loop_rule.vue";

export default {
  components: {
    ChangeName,
    CrewRule,
    ValveRule,
    PumpRule,
    BackWaterRule,
    LoopRule,
  },
  data() {
    return {
      id_device: "",
      value: "",
      options: [
        { id: -1, text: "未配置" },
        { id: 10, text: "机组" },
        { id: 11, text: "补水阀" },
        { id: 12, text: "回水阀" },
        { id: 13, text: "供水泵" },
        { id: 14, text: "循环泵" },
      ],
      show3: false,
      // 端口列表
      portList: [],
      // 是否显示loading框
      loading: false,
    };
  },
  methods: {
    // 父组件触发
    faRefresh() {
      this.id_device = this.$store.state.device.id_device;
      this.getChannelList();
    },

    // 获取通道列表
    async getChannelList() {
      const deviceType = this.$store.state.device.deviceType;
      if (!deviceType) return;
      const params = {
        id_device: this.id_device,
      };
      const { ret, data, msg } = await GetChannelListApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      data.map((i) => {
        i.show = false;
        i.task = [];
        i._task_type = i.task_type;
      });
      this.portList = data;
    },

    // 展开关闭
    async changeExpand(item) {
      const index = this.portList.findIndex((i) => i.id === item.id);
      if (item.show) {
        // 关闭
        this.portList[index].show = false;
      } else {
        // 展开
        const params = {
          id_channel_control: item.id,
        };
        const { ret, data, msg } = await GetChannelTaskListApi(params);
        if (ret !== 0) {
          return this.$message.error(msg);
        }
        this.portList[index].task = data;
        this.portList[index].show = true;
      }
    },

    // 显示修改名称
    openChangeName(item) {
      this.$refs.changeNameRef.open(item);
    },

    // 切换类型
    async changeTaskType(item) {
      if (item.channel_status === 1) {
        // 开启状态不允许切换状态
        item.task_type = item._task_type;
        this.$message.warning("请关闭通道后切换类型!");
        return;
      }
      const old = item._task_type;
      const new1 = item.task_type;
      if (!old) {
        // 直接设置
        const controlParams = {
          // 调用接口
          fn: UpdateChannelApi,
          // 接口参数
          params: {
            id_channel_control: item.id,
            task_type: new1,
          },
          // 接口调用完毕, 根据类型, 调用接口刷新数据
          type: 1,
          // 额外参数
          extra: "",
        };
        this.control(controlParams);
      } else {
        // 类型转换
        const result = await this.$confirm(
          "您确定要更改通道类型吗? 更改通道类型会同步删除通道任务!",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch(() => "cancel");
        if (result === "cancel") {
          this.getChannelList();
          return;
        }
        this.control({
          // 调用接口
          fn: UpdateChannelApi,
          // 接口参数
          params: {
            id_channel_control: item.id,
            task_type: new1,
          },
          // 接口调用完毕, 根据类型, 调用接口刷新数据
          type: 1,
          // 额外参数
          extra: "",
        });
      }
    },

    // 设备开 管
    async openClose(item, status) {
      const message = status
        ? "您确定要开启当前通道吗? 手动开启通道将会停用通道已启用的控制规则"
        : "您确定要关闭当前通道吗?";
      const result = await this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch(() => "cancel");
      if (result === "cancel") return;
      this.control({
        fn: UpdateChannelApi,
        params: {
          id_channel_control: item.id,
          channel_status: status,
        },
        type: 1,
        extra: "",
      });
    },

    // 显示添加规则的弹窗
    showAddRule(item) {
      const idProject = this.$store.state.device.id_project;
      if (item.task_type === 10) {
        this.$refs.crewRuleRef.add(item, idProject);
      } else if (item.task_type === 11) {
        this.$refs.valveRef.add(item, idProject);
      } else if (item.task_type === 12) {
        this.$refs.backWaterRef.add(item, idProject);
      } else if (item.task_type === 13) {
        this.$refs.pumpRuleRef.add(item, idProject);
      } else if (item.task_type === 14) {
        this.$refs.loopRuleRef.add(item);
      } else {
        this.$message.warning("无法为未配置类型通道设置规则!");
      }
    },

    // 启用控制规则
    async useRule(row, ru, state) {
      const result = await this.$confirm(
        `您确定要${state === 1 ? "启用" : "停用"}当前控制规则吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch(() => "cancel");
      if (result === "cancel") return;
      this.control({
        fn: UpdateChannelTaskApi,
        params: {
          id_channel_control: row.id,
          id_control_auto_list: [ru.id],
          state,
        },
        type: 2,
        extra: row,
      });
    },

    // 删除控制规则
    async delRule(row, ru) {
      const result = await this.$confirm("您确定要删除当前控制规则吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch(() => "cancel");
      if (result === "cancel") return;
      this.control({
        fn: UpdateChannelTaskApi,
        params: {
          id_channel_control: row.id,
          id_control_auto_list: [ru.id],
          state: 0,
        },
        type: 2,
        extra: row,
      });
    },

    // 等同于装饰器 ---------------------------
    async control(controlParams) {
      const instince = Loading.service({
        fullscreen: true,
        text: "操作中,请稍后...",
      });
      const { fn, params, type, extra } = controlParams;
      const { ret, data, msg } = await fn(params);
      if (ret !== 0) {
        instince.close();
        this.$message.error(msg);
        return;
      }
      this.$message.success("操作成功");
      instince.close();
      if (type === 1) {
        // 更改了通道
        this.getChannelList();
      } else {
        // 操作了规则
        const port = { ...extra };
        port.show = false;
        this.changeExpand(port);
      }
    },
  },

  created() {
    this.id_device = this.$store.state.device.id_device;
    this.getChannelList();
  },
  computed: {
    // 设备类型
    deviceType() {
      return this.$store.state.device.deviceType;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 290px);
  padding: 0 72px 12px;

  .item {
    width: 100%;
    height: auto;

    .title_box {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 19px;
      background-color: #f8faff;

      .left_box {
        display: flex;
        align-items: center;
        flex: 0 0 270px;

        .expand {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform-origin: 50%;
          transition: all 0.28s;
        }

        .rotate {
          transform: rotate(90deg);
        }

        .port_name {
          margin-left: 18px;
          font-size: 14px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #000000;
        }

        .name {
          margin: 0 9px 0 0px;
          font-size: 14px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #000000;
        }

        .state1 {
          padding: 3px 7px;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffa44a;
          margin-left: 12px;
          background: rgba(255, 164, 74, 0.2);
          border-radius: 4px 4px 4px 4px;
        }

        .state2 {
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #c3c3c4;
          margin-left: 12px;
        }
      }

      .select {
        flex: 1;
        text-align: center;
      }

      .btn_box {
        flex: 0 0 200px;
        text-align: right;
      }
    }

    .content_box {
      display: flex;
      border: 1px solid #eff2f7;

      .title {
        flex: 0 0 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        border-right: 1px solid #eff2f7;
      }

      .content {
        flex: 1;

        .add_box {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 24px;
        }

        .rule_box {
          border-top: 1px solid #eff2f7;
          display: flex;

          .rule_name_state {
            flex: 0 0 200px;
            display: flex;
            border-right: 1px solid #eff2f7;
            padding: 7px 0 7px 24px;

            .name {
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #333333;
            }

            .state8 {
              width: 42px;
              height: 20px;
              line-height: 20px;
              background: rgba(255, 164, 74, 0.2);
              border-radius: 4px;
              font-size: 10px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #ffa44a;
              text-align: center;
              margin-left: 4px;
            }
          }

          .rule_detail {
            flex: 1;

            .line {
              height: 36px;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #333333;

              .desc {
                flex: 1;
                text-align: right;
                padding-right: 12px;
              }

              .result {
                flex: 2;

                .week {
                  margin-right: 12px;
                }

                .ot {
                  margin-right: 18px;
                }
              }
            }
          }

          .operate_box {
            flex: 0 0 180px;
            text-align: right;
            padding: 7px 42px 7px 0;
          }
        }
      }
    }
  }

  .not_control {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 302px);
    user-select: none;

    img {
      width: 281px;
      height: 235px;
    }

    span {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #3d3d3d;
      margin-top: 36px;
    }
  }
}
</style>
