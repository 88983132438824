<template>
  <el-dialog
    :title="isEdit ? '修改机组' : '添加机组'"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="resetForm"
  >
    <el-form
      :model="crewForm"
      :rules="rules"
      ref="crewRef"
      label-width="100px"
    >
      <el-form-item
        label="机组名称："
        prop="name"
      >
        <el-input
          v-model.trim="crewForm.name"
          placeholder="请输入机组名称"
          maxlength="8"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="功率"
        prop="power"
      >
        <el-input
          v-model.trim="crewForm.power"
          placeholder="请输入功率"
          maxlength="3"
          class="unit_kw"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="机组电流："
      >
        <el-select
          v-model="crewForm.machine_index"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in electricList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="循环泵："
      >
        <el-select
          v-model="crewForm.loop_pump_index"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in electricList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="关联水箱："
        prop="id_wbox"
      >
        <el-select
          v-model="crewForm.id_wbox"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in waterCaseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="confirm"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddCrew, UpdateCrew } from '@/api'

// 生成器
const generator = (str, len) => {
  const arr = Array.from({ length: len }, (_, index) => {
    return {
      id: index,
      name: str + (index + 1)
    }
  })
  return [{ id: -1, name: '未配置' }].concat(arr)
}

const checkPower = (rule, value, callback) => {
  const regExp = /^[1-9]{1}[0-9]{0,2}$/
  if (!regExp.test(value)) {
    return callback(new Error('功率输入错误'))
  }
  callback()
}

export default {
  data() {
    return {
      // 添加还是修改
      isEdit: false,
      // 设备号
      id_device: '',
      // 是否显示 弹窗
      dialogVisible: false,
      // 水箱列表
      waterCaseList: [],
      // 机组表单
      crewForm: {
        id: '',
        // 名称
        name: '',
        // 功率
        power: '',
        // 机组电流
        machine_index: -1,
        // 循环泵
        loop_pump_index: -1,
        // 关联水箱id
        id_wbox: ''
      },
      // 机组电流
      electricList: [],
      // 规则
      rules: {
        name: [{ required: true, message: '请输入机组名称', trigger: 'blur' }],
        power: [
          { required: true, message: '请输入功率', trigger: 'blur' },
          { validator: checkPower, trigger: 'blur' }
        ],
        id_wbox: [
          { required: true, message: '请选择关联水箱', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs.crewRef.resetFields()
    },

    // 添加机组
    add(device, waterCaseList) {
      // 动态填充下拉框内容
      const type = this.$store.state.device.deviceType
      if (type === 1) {
        // 蓝牙版
        this.electricList = generator('E', 6)
      } else {
        // 老版
        this.electricList = generator('E', 12)
      }
      this.waterCaseList = waterCaseList
      this.id_device = device
      this.isEdit = false
      this.dialogVisible = true
    },

    // 编辑机组
    edit(row, waterCaseList) {
      // 动态填充下拉框内容
      const type = this.$store.state.device.deviceType
      if (type === 1) {
        // 蓝牙版
        this.electricList = generator('E', 6)
      } else {
        // 老版
        this.electricList = generator('E', 12)
      }

      this.waterCaseList = waterCaseList
      this.isEdit = true
      this.dialogVisible = true
      this.$nextTick(() => {
        this.crewForm.id = row.id
        this.crewForm.machine_index =
          row.machine_index === null ? -1 : row.machine_index
        this.crewForm.loop_pump_index =
          row.loop_pump_index === null ? -1 : row.loop_pump_index
        this.crewForm.name = row.name
        this.crewForm.power = row.power + ''
        this.crewForm.id_wbox = row.id_wbox
      })
    },

    // 点击确定
    confirm() {
      this.$refs.crewRef.validate(_ => {
        if (!_) return
        if (this.isEdit) {
          this.editCrew()
        } else {
          this.addCrew()
        }
      })
    },

    // 添加机组
    async addCrew() {
      const params = {
        id_device: this.id_device,
        power: this.crewForm.power / 1,
        name: this.crewForm.name,
        id_wbox: this.crewForm.id_wbox
      }
      if (this.crewForm.machine_index >= 0) {
        params.machine_index = this.crewForm.machine_index
      }
      if (this.crewForm.loop_pump_index >= 0) {
        params.loop_pump_index = this.crewForm.loop_pump_index
      }
      const { ret, data, msg } = await AddCrew(params)
      if (ret !== 0) {
        return this.$message.error(msg || '添加机组失败!')
      }
      this.$message.success('添加机组成功')
      this.$emit('refresh')
      this.dialogVisible = false
    },

    // 编辑机组
    async editCrew() {
      const params = {
        id_machine: this.crewForm.id,
        power: this.crewForm.power / 1,
        machine_index: this.crewForm.machine_index,
        loop_pump_index: this.crewForm.loop_pump_index,
        name: this.crewForm.name,
        id_wbox: this.crewForm.id_wbox
      }
      const { ret, data, msg } = await UpdateCrew(params)
      if (ret !== 0) {
        return this.$message.error(msg || '更新机组失败!')
      }
      this.$message.success('更新机组成功')
      this.$emit('refresh')
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
