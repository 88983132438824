<template>
  <el-dialog
    title="请输入名称"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="clearForm"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleFormRef"
      label-width="100px"
    >
      <el-form-item
        label="名称："
        prop="remark"
      >
        <el-input
          placeholder="请输入名称"
          v-model="ruleForm.remark"
          maxlength="8"
        />
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="confirm"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { UpdateChannelApi } from '@/api'

export default {
  data() {
    return {
      // 管道信息
      channel: {},
      dialogVisible: false,
      // 表单
      ruleForm: {
        remark: ''
      },
      // 规则
      rules: {
        remark: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 清除表单
    clearForm() {
      this.$refs.ruleFormRef.resetFields()
    },

    open(channel) {
      this.channel = channel
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.remark = channel.remark
      })
    },

    confirm() {
      this.$refs.ruleFormRef.validate(async _ => {
        if (!_) return
        const params = {
          id_channel_control: this.channel.id,
          remark: this.ruleForm.remark
        }
        const { ret, data, msg } = await UpdateChannelApi(params)
        if (ret !== 0) {
          return this.$message.error(msg)
        }
        this.$message.success('更新成功')
        this.dialogVisible = false
        this.$emit('refresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
