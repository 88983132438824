<template>
  <div class="tab_three">
    <!-- tabbar -->
    <div class="subnav">
      <div class="left_box">
        <div :class="nav === 1 ? 'current' : 'nav'" @click="changeNav(1)">系统设置</div>
        <div :class="nav === 2 ? 'current' : 'nav'" @click="changeNav(2)">系统控制</div>
      </div>
      <div class="right_box">
        <div class="desc">环境温度</div>
        <el-select v-model="temp_index" placeholder="请选择端口" class="select_cpt">
          <el-option
            v-for="item in tempPort"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 动态组件 -->
    <component :is="getComponent" ref="tabThreeRef" />
  </div>
</template>

<script>
import SystemSetting from "./tab_three_component/system_setting.vue";
import SystemControl from "./tab_three_component/system_control.vue";

// 生成器
const generator = (str, len) => {
  const arr = Array.from({ length: len }, (_, index) => {
    return {
      id: index,
      name: str + (index + 1),
    };
  });
  return [{ id: -1, name: "未配置" }].concat(arr);
};

export default {
  props: ["id_device"],
  data() {
    return {
      // 1 系统设置 2 系统控制
      nav: 1,
    };
  },
  methods: {
    // 父组件触发 切换了设备
    faRefresh() {
      this.$refs.tabThreeRef.faRefresh();
    },
    // 更改tab
    changeNav(nav) {
      if (this.nav === nav) return;
      this.nav = nav;
    },
  },
  computed: {
    temp_index: {
      get() {
        return this.$store.state.device.temp_index;
      },
      set(v) {
        this.$store.dispatch("device/setDevice", {
          temp_index: v,
        });
      },
    },
    getComponent() {
      if (this.nav === 1) {
        return SystemSetting;
      } else {
        return SystemControl;
      }
    },
    tempPort() {
      const type = this.$store.state.device.deviceType;
      if (type === 1) {
        return generator("T", 4);
      } else {
        return generator("T", 8);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab_three {
  background-color: #fff;
  border-radius: $radius;
}

.subnav {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_box {
    display: flex;
    align-items: center;

    .nav {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #999999;
      margin: 0 24px;
      cursor: pointer;
    }

    .current {
      @extend .nav;
      color: #1b2351;
    }
  }

  .right_box {
    display: flex;
    align-items: center;

    .desc {
      font-weight: 600;
      color: #222;
      font-size: 14px;
    }

    .select_cpt {
      width: 160px;
      margin: 0 24px;
    }
  }
}
</style>
