
<template>
  <el-dialog
    title="编辑推送"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
  >
    <div class="form">
      <div class="column">
        <div class="desc">推送时间：</div>
        <div class="content">
          <el-time-select
            v-model="time"
            :picker-options="{
              start: '08:00',
              step: '00:30',
              end: '18:00'
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </div>
      </div>

      <div class="column">
        <div class="desc">推送方式：</div>
        <div class="content">
          <el-checkbox
            v-model="msgPush"
            @change="msgPushChange"
          >短信推送</el-checkbox>
          <el-checkbox v-model="appPush">App推送</el-checkbox>
        </div>
      </div>

      <template v-if="msgPush">
        <div
          class="column"
          v-for="item, index in telList"
          :key="index"
        >
          <div class="desc">{{ !index ? '推送手机号：' : '' }}</div>
          <div class="content">
            <el-input
              placeholder="请输入手机号"
              v-model="item.tel"
              maxlength="11"
            />
            <img
              @click="addTelTemplate"
              v-if="index + 1 === telList.length && telList.length < 4"
              src="../../../../assets/add-o.png"
              alt=""
            >
          </div>
        </div>
      </template>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="confirm"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { UpdatePushApi } from '@/api'

export default {
  data() {
    return {
      // 修改的推送id
      id: '',
      // 是否显示弹簧
      dialogVisible: false,
      // 推送时间节点
      time: '',
      // 短信推送
      msgPush: false,
      // app推送
      appPush: false,
      // 已存在的手机列表
      telList: [
        { tel: '' }
      ]
    }
  },
  methods: {
    open(info) {
      this.id = info.id
      if (info.push_time) {
        this.time = info.push_time.substring(0, 5)
      }
      // 短信推送
      if (info.push_type & 1) {
        this.msgPush = true
        const tels = []
        info.tel.map(i => {
          tels.push({ tel: i.tel })
        })
        this.telList = tels
      } else {
        this.msgPush = false
      }
      if (info.push_type & 2) {
        this.appPush = true
      } else {
        this.appPush = false
      }
      this.dialogVisible = true
    },

    // 添加手机号模板
    addTelTemplate() {
      this.telList.push({
        tel: ''
      })
    },

    // app推送模板
    msgPushChange() {
      this.telList = [{ tel: '' }]
    },

    // 点击了确定
    async confirm() {
      if (!this.time) {
        return this.$message.warning('请选择推送时间')
      }
      if (!this.msgPush && !this.appPush) {
        return this.$message.warning('请选择推送方式')
      }
      const result = this.telList.filter(i => i.tel !== '')
      if (this.msgPush && !result.length) {
        return this.$message.warning('请至少输入一个推送手机号')
      }
      // 手机号不规范
      let isTelError = false
      const reg = /^1[0-9]{10}$/
      this.telList.map(i => {
        if (i.tel && !reg.test(i.tel)) {
          isTelError = true
        }
      })
      if (isTelError) {
        return this.$message.warning('请检查手机号输入')
      }
      // 过滤手机号重复
      const temp = []
      let isRepeat = false
      this.telList.map(i => {
        if (i.tel) {
          const idx = temp.findIndex(j => j === i.tel)
          if (idx >= 0) {
            isRepeat = true
          } else {
            temp.push(i.tel)
          }
        }
      })
      if (isRepeat) {
        return this.$message.warning('手机号重复')
      }

      const params = {
        id: this.id,
        push_type: 0,
        push_time: this.time + ':00',
        tel: []
      }
      if (this.appPush) {
        params.push_type += 2
      }
      if (this.msgPush) {
        params.push_type += 1
      }
      if (params.push_type & 1) {
        this.telList.map(i => {
          if (i.tel) {
            params.tel.push(i.tel)
          }
        })
      }
      const { ret, data, msg } = await UpdatePushApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('编辑成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 400px;
  margin: 0 auto;

  .column {
    display: flex;
    margin-top: 16px;
    align-items: center;

    .desc {
      flex: 0 0 120px;
      text-align: right;
    }

    .content {
      display: flex;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        margin-left: 12px;
      }
    }
  }
}
</style>
