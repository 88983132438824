<template>
  <div class="early_warning_track">
    <!-- 选择器 -->
    <div class="select_box">
      <div class="input_box mr12 mt12">
        <div class="label">异常类型</div>
        <el-select v-model="type_err" placeholder="请选择">
          <el-option
            v-for="ite in typeErrList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>
      </div>

      <div class="input_box mr12 mt12">
        <div class="label">预警类型</div>
        <el-select v-model="warn_level" placeholder="请选择">
          <el-option
            v-for="ite in warnLevelList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>
      </div>

      <div class="date_box mr12 mt12">
        <div class="label">预警日期</div>
        <el-date-picker
          class="date-picker"
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>

      <div class="btn_box mr12 mt12">
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button size="small" type="primary" plain @click="reset">重置</el-button>
      </div>
    </div>

    <!-- 表格数据 -->
    <div class="table_box">
      <el-table
        :data="errorList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb',
          height: '40px',
        }"
        :stripe="true"
        size="small"
      >
        <el-table-column prop="time_start" label="预警时间"></el-table-column>
        <el-table-column label="通知时间">
          <template v-slot="scope">
            <span>{{ scope.row.time_notify || "-" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="异常类型/预值">
          <template v-slot="scope">
            <div>{{ scope.row.type_err | typeErrFmt }}</div>
            <div class="red">
              {{ scope.row.trigger_value }}{{ scope.row.type_err | unitFmt }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="预警类型/范围">
          <template v-slot="scope">
            <div :class="scope.row.warn_name === '默认预警' ? 'red' : ''">
              {{ scope.row.warn_name }}
            </div>
            <div>范围：{{ getRange(scope.row) }}</div>
          </template>
        </el-table-column>

        <el-table-column label="解除时间">
          <template v-slot="scope">
            <span>{{ scope.row.time_stop || "-" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="设置持续时间">
          <template v-slot="scope">
            <span>{{ scope.row.warn_time_duration || "-" }}(min)</span>
          </template>
        </el-table-column>

        <el-table-column label="预警持续时间">
          <template v-slot="scope">
            <span>{{ scope.row.time_duration || "-" }}(min)</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--分页-->
    <div class="pagination_box">
      <el-pagination
        @size-change="sizeChange"
        @current-change="CurrentChange"
        :current-page="page_id + 1"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="page_num"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { GetErrorList } from "@/api";

export default {
  data() {
    return {
      // 设备号
      id_device: "",
      // 异常类型
      type_err: "",
      // 异常类型列表 1：水位、2：水温、3：回水温、3：供水压力
      typeErrList: [
        { id: 1, name: "水位" },
        { id: 2, name: "水温" },
        { id: 3, name: "回水温" },
        { id: 4, name: "供水压力" },
      ],
      // 预警类型
      warn_level: "",
      // 预警类型列表1：默认预警 2：自定义预警
      warnLevelList: [
        { id: 1, name: "默认预警" },
        { id: 2, name: "自定义预警" },
      ],
      // 预警日期
      date: ["", ""],
      page_id: 0,
      page_num: 20,
      total: 0,
      // 异常列表
      errorList: [],
    };
  },
  methods: {
    faRefresh() {
      this.id_device = this.$store.getters.id_device;
      this.getErrList();
    },

    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s;
      this.page_id = 0;
      this.getErrList();
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p;
      this.getErrList();
    },

    // 查询
    search() {
      this.page_id = 0;
      this.getErrList();
    },

    // 重置
    reset() {
      this.type_err = this.warn_level = "";
      this.date = null;
      this.page_id = 0;
      this.getErrList();
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num,
        id_device: this.id_device,
      };
      if (this.type_err !== "") {
        params.type_err = this.type_err;
      }
      if (this.warn_level !== "") {
        params.warn_level = this.warn_level;
      }
      if (this.date && this.date[0] && this.date[1]) {
        params.time_start = this.date[0] + " 00:00:00";
        params.time_end = this.date[1] + " 23:59:59";
      }
      return params;
    },

    // 获取异常列表
    async getErrList() {
      const params = this.getParams();
      const { ret, data, msg } = await GetErrorList(params);
      if (ret !== 0) {
        return this.$message.error(msg || "获取异常列表失败!");
      }
      this.total = data.cnt_all;
      this.errorList = data.data;
    },

    // 获取异常范围
    getRange(row) {
      const unit = ["％", "℃", "MPa", "MPa"];
      return `小于${row.warn_min}${unit[row.type_err - 1]},大于${row.warn_max}${
        unit[row.type_err - 1]
      }`;
    },
  },

  created() {
    this.id_device = this.$store.getters.id_device;
    this.getErrList();
  },

  filters: {
    // 异常类型
    typeErrFmt(v) {
      const t = ["水位", "水温", "回水温", "供水压力"];
      return t[v - 1];
    },
    // 单位格式化
    unitFmt(v) {
      const t = ["％", "℃", "MPa", "MPa"];
      return t[v - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
.table_box {
  padding: 0 12px;
  margin-top: 12px;

  .red {
    color: red;
  }
}
</style>
