<template>
  <!-- 系统列表 -->
  <div class="system_box">
    <div class="item_box">
      <div class="header_box">
        <div class="left_box">
          <div class="color1"></div>
          <div class="name">水箱</div>
        </div>

        <div class="right_box" v-if="list1.length < 4" @click="addWaterCase">
          <img src="../../../../assets/add.png" alt="" />
          <span>添加水箱</span>
        </div>
      </div>

      <div class="table_box">
        <el-table
          :data="list1"
          style="width: 100%"
          :header-cell-style="{
            background: '#fafafb',
          }"
        >
          <el-table-column prop="name" label="名称" />
          <el-table-column prop="name" label="高度">
            <template #default="{ row }">
              <span>{{ row.vol }}m</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="吨位">
            <template #default="{ row }">
              <span>{{ row.volume }}t</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="水位">
            <template #default="{ row }">
              <span>
                {{
                  row.level_index === null
                    ? "--"
                    : (deviceType ? "P" : "L") + (row.level_index + 1)
                }}</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="name" label="水温">
            <template #default="{ row }">
              <span>
                {{ row.temp_index !== null ? "T" + (row.temp_index + 1) : "--" }}</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="name" label="补水阀电流">
            <template #default="{ row }">
              <span>
                {{
                  row.supply_water_index !== null
                    ? "E" + (row.supply_water_index + 1)
                    : "--"
                }}</span
              >
            </template>
          </el-table-column>

          <template v-if="deviceType !== 1">
            <el-table-column prop="name" label="最大量程">
              <template #default="{ row }">
                <span> {{ row.level_range !== null ? row.level_range : "--" }}m</span>
              </template>
            </el-table-column>
          </template>

          <el-table-column label="操作">
            <template #default="{ row }">
              <span class="edit" @click="editWaterCase(row)">编辑</span>
              <span class="edit" @click="delWaterCase(row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="item_box">
      <div class="header_box">
        <div class="left_box">
          <div class="color2"></div>
          <div class="name">水泵</div>
        </div>

        <div class="right_box" v-if="list2.length < 4" @click="addPump">
          <img src="../../../../assets/add.png" alt="" />
          <span>添加水泵</span>
        </div>
      </div>

      <div class="table_box">
        <el-table
          :data="list2"
          style="width: 100%"
          :header-cell-style="{
            background: '#fafafb',
          }"
        >
          <el-table-column label="类型">
            <template #default="{ row }">
              <span>{{ row.pump_type ? "循环泵" : "供水泵" }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="名称" />
          <el-table-column label="压力">
            <template #default="{ row }">
              <span>{{
                row.pressure_index !== null ? "P" + (row.pressure_index + 1) : "--"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="回水温">
            <template #default="{ row }">
              <span>{{
                row.back_water_index !== null ? "T" + (row.back_water_index + 1) : "--"
              }}</span>
            </template>
          </el-table-column>

          <el-table-column label="电流">
            <template #default="{ row }">
              <span>{{
                row.water_pump_index !== null ? "E" + (row.water_pump_index + 1) : "--"
              }}</span>
            </template>
          </el-table-column>

          <el-table-column label="回水阀电流">
            <template #default="{ row }">
              <span>{{
                row.back_valve_index !== null ? "E" + (row.back_valve_index + 1) : "--"
              }}</span>
            </template>
          </el-table-column>

          <template v-if="deviceType !== 1">
            <el-table-column label="最大量程">
              <template #default="{ row }">
                <span>{{ row.pressure_range }}MPa</span>
              </template>
            </el-table-column>
          </template>

          <el-table-column label="操作">
            <template #default="{ row }">
              <span class="edit" @click="editPump(row)">编辑</span>
              <span class="edit" @click="delPump(row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="item_box">
      <div class="header_box">
        <div class="left_box">
          <div class="color3"></div>
          <div class="name">机组</div>
        </div>

        <div class="right_box" v-if="list3.length < 4" @click="addCrew">
          <img src="../../../../assets/add.png" alt="" />
          <span>添加机组</span>
        </div>
      </div>

      <div class="table_box">
        <el-table
          :data="list3"
          style="width: 100%"
          :header-cell-style="{
            background: '#fafafb',
          }"
        >
          <el-table-column prop="name" label="名称" />
          <el-table-column label="功率">
            <template #default="{ row }">
              <span>{{ row.power }}kw</span>
            </template>
          </el-table-column>
          <el-table-column label="电流">
            <template #default="{ row }">
              <span>{{
                row.machine_index !== null ? "E" + (row.machine_index + 1) : "--"
              }}</span>
            </template>
          </el-table-column>

          <el-table-column label="循环泵电流">
            <template #default="{ row }">
              <span>{{
                row.loop_pump_index !== null ? "E" + (row.loop_pump_index + 1) : "--"
              }}</span>
            </template>
          </el-table-column>

          <el-table-column label="关联水箱" prop="name_wbox" />

          <el-table-column label="操作">
            <template #default="{ row }">
              <span class="edit" @click="editCrew(row)">编辑</span>
              <span class="edit" @click="delCrew(row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 添加 修改 机组 -->
    <ChangeCrew ref="crewRef" @refresh="getCrewList" />
    <!-- 添加 修改 水箱 -->
    <ChangeWaterCase ref="waterCaseRef" @refresh="getWaterCaseList" />
    <!-- 添加 修改 供水管道 -->
    <ChangePump ref="waterPumpRef" @refresh="getWaterPipeList" />
  </div>
</template>

<script>
import {
  GetCrew,
  DelCrew,
  GetWaterCase,
  DelWaterCase,
  GetWaterPipe,
  DelProvidePipe,
} from "@/api";
import ChangeCrew from "./system_setting_component/change_crew.vue";
import ChangeWaterCase from "./system_setting_component/change_water_case.vue";
import ChangePump from "./system_setting_component/change_pump.vue";

export default {
  components: { ChangeCrew, ChangeWaterCase, ChangePump },
  data() {
    return {
      // 设备编号
      id_device: "",
      // 项目id
      id_project: "",
      // 水箱 水泵 机组
      list1: [],
      list2: [],
      list3: [],
    };
  },
  methods: {
    // 父组件触发的刷新
    faRefresh() {
      this.id_device = this.$store.getters.id_device;
      this.id_project = this.$store.getters.id_project;
      // 获取水箱
      this.getWaterCaseList();
      // 获取水泵
      this.getWaterPipeList();
      // 获取机组列表
      this.getCrewList();
    },

    // 获取水箱列表
    async getWaterCaseList() {
      const params = { id_device: this.id_device };
      const { ret, data, msg } = await GetWaterCase(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.list1 = data;
    },

    // 添加水箱
    addWaterCase() {
      this.$refs.waterCaseRef.add(this.id_device);
    },

    // 删除水箱
    async delWaterCase(item) {
      const result = await this.$confirm("是否删除该水箱?", "删除提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch(() => "cancel");
      if (result === "cancel") return;
      const params = {
        id_wbox: item.id,
      };
      const { ret, data, msg } = await DelWaterCase(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.$message.success("删除成功");
      this.getWaterCaseList();
    },

    // 编辑水箱
    editWaterCase(item) {
      this.$refs.waterCaseRef.edit(item);
    },

    // 获取供水管
    async getWaterPipeList() {
      const params = { id_device: this.id_device };
      const { ret, data, msg } = await GetWaterPipe(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.list2 = data;
    },

    // 添加水泵
    addPump() {
      this.$refs.waterPumpRef.add(this.id_device);
    },

    // 删除水泵
    async delPump(item) {
      const result = await this.$confirm("是否删除该水泵?", "删除提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch(() => "cancel");
      if (result === "cancel") return;
      const params = { id_pipe: item.id };
      const { ret, data, msg } = await DelProvidePipe(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.$message.success("删除成功");
      this.getWaterPipeList();
    },

    // 编辑水泵
    editPump(item) {
      this.$refs.waterPumpRef.edit(item);
    },

    // 获取机组列表
    async getCrewList() {
      const params = { id_device: this.id_device };
      const { ret, data, msg } = await GetCrew(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.list3 = data.machine_array;
      console.log(data.machine_array);
    },

    // 添加机组
    addCrew() {
      this.$refs.crewRef.add(this.id_device, this.list1);
    },

    // 删除机组
    async delCrew(item) {
      const result = await this.$confirm("是否删除该机组?", "删除提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch(() => "cancel");
      if (result === "cancel") return;
      const params = {
        id_machine: item.id,
      };
      const { ret, data, msg } = await DelCrew(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("删除成功");
      this.getCrewList();
    },

    // 编辑机组
    editCrew(item) {
      this.$refs.crewRef.edit(item, this.list1);
    },
  },
  created() {
    this.id_project = this.$store.getters.id_project;
    this.id_device = this.$store.getters.id_device;

    // 获取水箱
    this.getWaterCaseList();
    // 获取水泵
    this.getWaterPipeList();
    // 获取机组列表
    this.getCrewList();
  },
  filters: {
    levelIndexFmt(v, u) {
      switch (v) {
        case null:
          return "未配置";
        default:
          return v + 1;
      }
    },
  },
  computed: {
    deviceType() {
      return this.$store.state.device.deviceType;
    },
  },
};
</script>

<style lang="scss" scoped>
.system_box {
  padding: 0 12px 17px;
  background-color: #fff;
}

.item_box {
  border: 1px solid #ebeef5;
  border-radius: 12px;

  &:nth-child(n + 2) {
    margin-top: 17px;
  }

  // background: linear-gradient(180deg, #dce4ff 0%, #ffffff 46%);

  .header_box {
    height: 55px;
    background: #f0f4ff;
    border-radius: 12px 12px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 23px;

    .left_box {
      display: flex;
      align-items: center;

      .color1 {
        width: 11px;
        height: 11px;
        background: #00b578;
        box-shadow: 0px 0px 10px 0px #00b578;
        border-radius: 50%;
      }

      .color2 {
        @extend .color1;
        background: #2a47b0;
        box-shadow: 0px 0px 10px 0px #2a47b0;
      }

      .color3 {
        @extend .color1;
        background: #ff8f1f;
        box-shadow: 0px 0px 10px 0px #ff8f1f;
      }

      .name {
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 500;
        font-size: 16px;
        color: #3d3d3d;
        margin-left: 16px;
      }
    }

    .right_box {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        margin-left: 6px;
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 400;
        font-size: 14px;
        color: #2a47b0;
      }
    }
  }

  .table_box {
    padding: 16px;

    .edit {
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 400;
      font-size: 14px;
      color: #2a47b0;
      margin-right: 16px;
      cursor: pointer;
    }
  }
}
</style>
