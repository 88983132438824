var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"system_box"},[_c('div',{staticClass:"item_box"},[_c('div',{staticClass:"header_box"},[_vm._m(0),(_vm.list1.length < 4)?_c('div',{staticClass:"right_box",on:{"click":_vm.addWaterCase}},[_c('img',{attrs:{"src":require("../../../../assets/add.png"),"alt":""}}),_c('span',[_vm._v("添加水箱")])]):_vm._e()]),_c('div',{staticClass:"table_box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list1,"header-cell-style":{
          background: '#fafafb',
        }}},[_c('el-table-column',{attrs:{"prop":"name","label":"名称"}}),_c('el-table-column',{attrs:{"prop":"name","label":"高度"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.vol)+"m")])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"吨位"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.volume)+"t")])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"水位"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.level_index === null ? "--" : (_vm.deviceType ? "P" : "L") + (row.level_index + 1)))])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"水温"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.temp_index !== null ? "T" + (row.temp_index + 1) : "--"))])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"补水阀电流"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.supply_water_index !== null ? "E" + (row.supply_water_index + 1) : "--"))])]}}])}),(_vm.deviceType !== 1)?[_c('el-table-column',{attrs:{"prop":"name","label":"最大量程"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.level_range !== null ? row.level_range : "--")+"m")])]}}],null,false,1491836626)})]:_vm._e(),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.editWaterCase(row)}}},[_vm._v("编辑")]),_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.delWaterCase(row)}}},[_vm._v("删除")])]}}])})],2)],1)]),_c('div',{staticClass:"item_box"},[_c('div',{staticClass:"header_box"},[_vm._m(1),(_vm.list2.length < 4)?_c('div',{staticClass:"right_box",on:{"click":_vm.addPump}},[_c('img',{attrs:{"src":require("../../../../assets/add.png"),"alt":""}}),_c('span',[_vm._v("添加水泵")])]):_vm._e()]),_c('div',{staticClass:"table_box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list2,"header-cell-style":{
          background: '#fafafb',
        }}},[_c('el-table-column',{attrs:{"label":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.pump_type ? "循环泵" : "供水泵"))])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"名称"}}),_c('el-table-column',{attrs:{"label":"压力"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.pressure_index !== null ? "P" + (row.pressure_index + 1) : "--"))])]}}])}),_c('el-table-column',{attrs:{"label":"回水温"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.back_water_index !== null ? "T" + (row.back_water_index + 1) : "--"))])]}}])}),_c('el-table-column',{attrs:{"label":"电流"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.water_pump_index !== null ? "E" + (row.water_pump_index + 1) : "--"))])]}}])}),_c('el-table-column',{attrs:{"label":"回水阀电流"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.back_valve_index !== null ? "E" + (row.back_valve_index + 1) : "--"))])]}}])}),(_vm.deviceType !== 1)?[_c('el-table-column',{attrs:{"label":"最大量程"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.pressure_range)+"MPa")])]}}],null,false,2323376278)})]:_vm._e(),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.editPump(row)}}},[_vm._v("编辑")]),_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.delPump(row)}}},[_vm._v("删除")])]}}])})],2)],1)]),_c('div',{staticClass:"item_box"},[_c('div',{staticClass:"header_box"},[_vm._m(2),(_vm.list3.length < 4)?_c('div',{staticClass:"right_box",on:{"click":_vm.addCrew}},[_c('img',{attrs:{"src":require("../../../../assets/add.png"),"alt":""}}),_c('span',[_vm._v("添加机组")])]):_vm._e()]),_c('div',{staticClass:"table_box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list3,"header-cell-style":{
          background: '#fafafb',
        }}},[_c('el-table-column',{attrs:{"prop":"name","label":"名称"}}),_c('el-table-column',{attrs:{"label":"功率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.power)+"kw")])]}}])}),_c('el-table-column',{attrs:{"label":"电流"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.machine_index !== null ? "E" + (row.machine_index + 1) : "--"))])]}}])}),_c('el-table-column',{attrs:{"label":"循环泵电流"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.loop_pump_index !== null ? "E" + (row.loop_pump_index + 1) : "--"))])]}}])}),_c('el-table-column',{attrs:{"label":"关联水箱","prop":"name_wbox"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.editCrew(row)}}},[_vm._v("编辑")]),_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.delCrew(row)}}},[_vm._v("删除")])]}}])})],1)],1)]),_c('ChangeCrew',{ref:"crewRef",on:{"refresh":_vm.getCrewList}}),_c('ChangeWaterCase',{ref:"waterCaseRef",on:{"refresh":_vm.getWaterCaseList}}),_c('ChangePump',{ref:"waterPumpRef",on:{"refresh":_vm.getWaterPipeList}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left_box"},[_c('div',{staticClass:"color1"}),_c('div',{staticClass:"name"},[_vm._v("水箱")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left_box"},[_c('div',{staticClass:"color2"}),_c('div',{staticClass:"name"},[_vm._v("水泵")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left_box"},[_c('div',{staticClass:"color3"}),_c('div',{staticClass:"name"},[_vm._v("机组")])])}]

export { render, staticRenderFns }