<template>
  <el-dialog
    title="曲线设置"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
  >
    <div class="filter_container">
      <div class="selected_box">
        <el-scrollbar style="width: 100%; height: 100%">
          <div class="item_box" v-for="k in selectedJson" :key="k.type">
            <div class="title">{{ k.type | typeFmt }}</div>
            <div class="content">
              <span
                :class="'state' + k.type"
                v-for="m in k.children"
                :key="m.id"
                >{{ m.name }}</span
              >
            </div>
          </div>
        </el-scrollbar>
      </div>
      <ul class="select_box">
        <li
          :class="item.type === current ? 'current' : ''"
          v-for="item in typeList"
          :key="item.type"
          @click="changeType(item.type)"
        >
          {{ item.type | typeFmt }}
        </li>
      </ul>
      <div class="select_children">
        <el-checkbox v-model="checkedAll" @change="selectAll">全选</el-checkbox>
        <div class="child_box">
          <div class="item" v-for="item in getChild" :key="item.id">
            <el-checkbox v-model="item.selected" @change="changeSelected">{{
              item.name
            }}</el-checkbox>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      // 是否全选
      checkedAll: true,
      // 中间信息
      typeList: [],
      // 当前选中的类型
      current: '',
      // 选中的数据
      selectedJson: []
    }
  },
  methods: {
    // 显示
    open(data, ids) {
      if (!data.length) {
        return this.$message.warning('暂无可配置参数')
      }
      if (ids.length) {
        this.dialogVisible = true
        return
      }
      data.map((i) => {
        i.data_array.map((j) => {
          j.selected = true
        })
      })
      this.typeList = data
      this.current = data[0].type
      this.changeSelected()
      this.filterSelected()
      this.dialogVisible = true
    },

    // 修改选中类型
    changeType(type) {
      if (this.current === type) return
      this.current = type
      this.changeSelected()
    },

    // 子项复选框被点击
    changeSelected() {
      const index = this.typeList.findIndex((i) => i.type === this.current)
      const currentArr = this.typeList[index].data_array
      const selected = currentArr.filter((i) => i.selected === true)
      this.checkedAll = currentArr.length === selected.length
      this.filterSelected()
    },

    // 全选
    selectAll(flag) {
      const index = this.typeList.findIndex((i) => i.type === this.current)
      const currentArr = this.typeList[index].data_array
      currentArr.map((j) => (j.selected = flag))
      this.filterSelected()
    },

    // 过滤选中的数据
    filterSelected() {
      const res = []
      this.typeList.map((i) => {
        const obj = {
          type: i.type,
          children: []
        }
        i.data_array.map((j) => {
          if (j.selected) {
            obj.children.push({
              name: j.name,
              id: j.id
            })
          }
        })
        if (obj.children.length) {
          res.push(obj)
        }
      })
      this.selectedJson = res
    },

    // 确定
    confirm() {
      const ids = []
      this.selectedJson.map((i) => {
        i.children.map((j) => {
          ids.push({
            id: j.id,
            type: i.type,
            title: j.name
          })
        })
      })
      this.$emit('filterData', ids)
      this.dialogVisible = false
    }
  },
  computed: {
    // 获取当前选中item的子项
    getChild() {
      if (!this.current) {
        return []
      } else {
        return this.typeList.filter((i) => {
          return i.type === this.current
        })[0].data_array
      }
    }
  },
  filters: {
    // 类型格式化
    typeFmt(v) {
      switch (v) {
        case 1:
          return '水箱水位'
        case 2:
          return '水箱水温'
        case 3:
          return '回水温'
        case 4:
          return '供水压力'
        case 5:
          return '补水阀电流'
        case 6:
          return '循环泵电流'
        case 7:
          return '机组电流'
        case 8:
          return '供水泵电流'
        case 9:
          return '回水阀电流'
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.filter_container {
  width: 100%;
  display: flex;

  .selected_box {
    flex: 3;
    max-height: 300px;

    .item_box {
      margin-top: 12px;

      .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
      }

      .content {
        display: flex;
        flex-wrap: wrap;

        span {
          flex: 0 0 50%;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          padding-left: 12px;
          margin-top: 8px;
        }

        .state {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
          }
        }

        .state1 {
          @extend .state;
          &::after {
            background-color: #ff4e00;
          }
        }

        .state2 {
          @extend .state;
          &::after {
            background-color: #1eff00;
          }
        }

        .state3 {
          @extend .state;
          &::after {
            background-color: #fc2256;
          }
        }

        .state4 {
          @extend .state;
          &::after {
            background-color: #fabfd7;
          }
        }

        .state5 {
          @extend .state;
          &::after {
            background-color: #7c8776;
          }
        }

        .state6 {
          @extend .state;
          &::after {
            background-color: #0e53f8;
          }
        }

        .state7 {
          @extend .state;
          &::after {
            background-color: #3195f9;
          }
        }

        .state8 {
          @extend .state;
          &::after {
            background-color: skyblue;
          }
        }

        .state9 {
          @extend .state;
          &::after {
            background-color: #2a47b0;
          }
        }
      }
    }
  }

  .select_box {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #eff2f7;
    border-left: 1px solid #eff2f7;

    li {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #999999;
      cursor: pointer;

      &.current {
        color: #2a47b0;
      }
    }
  }

  .select_children {
    flex: 3;
    padding: 0 24px;

    .child_box {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;

      .item {
        height: 24px;
        flex: 0 0 50%;
      }
    }
  }
}
</style>
