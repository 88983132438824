<template>
  <!-- 供水 -->
  <el-dialog
    title="添加控制规则"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    @closed="clearForm"
  >
    <!-- 日期 -->
    <div class="week_box">
      <el-checkbox v-model="weeks.week1">周一</el-checkbox>
      <el-checkbox v-model="weeks.week2">周二</el-checkbox>
      <el-checkbox v-model="weeks.week3">周三</el-checkbox>
      <el-checkbox v-model="weeks.week4">周四</el-checkbox>
      <el-checkbox v-model="weeks.week5">周五</el-checkbox>
      <el-checkbox v-model="weeks.week6">周六</el-checkbox>
      <el-checkbox v-model="weeks.week7">周日</el-checkbox>
    </div>

    <!-- 规则名称 -->
    <div class="rule_name">
      <div class="input_box">
        <div class="label">规则名称</div>
        <input type="text" v-model="ruleName" maxlength="10" />
      </div>
    </div>

    <!-- 产水时间 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_1.png" />
          <span>供水时间</span>
          <div class="star">*</div>
        </div>
      </div>
      <div class="content">
        <div class="select_box" v-for="item in provideWaterTime" :key="item.id">
          <div class="item">
            <el-checkbox
              v-model="item.selected"
              @change="selectedChange(item)"
            ></el-checkbox>
            <span>{{ item.time_start }} - {{ item.time_end }}</span>
          </div>
        </div>

        <div class="empty_data" v-if="!provideWaterTime.length">暂未添加预设参数</div>
      </div>
    </div>

    <!-- 设置水位 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_4.png" />
          <span>设置水位</span>
        </div>
        <div class="right">
          <el-select v-model="index1" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <div class="ipt_box">
          <span class="tip">水位</span>
          <input type="text" placeholder="输入水位" maxlength="3" v-model="level_value" />
          <span class="unit">(％)</span>
        </div>
      </div>
    </div>

    <!-- 设置温度 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_4.png" />
          <span>设置温度</span>
        </div>
        <div class="right">
          <el-select v-model="index2" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <div class="ipt_box">
          <span class="tip">温度</span>
          <input type="text" placeholder="输入温度" maxlength="3" v-model="temp_value" />
          <span class="unit">(℃)</span>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="addControl">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetPreConfig, AddChannelTaskApi, GetWaterCase } from "@/api";

export default {
  data() {
    return {
      // 端口信息
      port: {},
      // 项目id
      id_project: "",
      // 设备号
      id_device: "",
      // 是否显示弹窗
      dialogVisible: false,
      weeks: {
        week1: true,
        week2: true,
        week3: true,
        week4: true,
        week5: true,
        week6: true,
        week7: true,
      },
      // 规则名称
      ruleName: "",
      // 产水时间
      provideWaterTime: [],
      // 水位
      level_value: "",
      options: [],
      index1: "",
      // 温度
      temp_value: "",

      index2: "",
    };
  },
  methods: {
    // 添加机组控制
    add(port, project) {
      this.port = port;
      this.id_device = port.id_device;
      this.id_project = project;
      this.getPreConfig();
      this.getWaterCaseList();
      this.dialogVisible = true;
    },

    // 获取预设配置
    async getPreConfig() {
      const params = {
        id_project: this.id_project,
      };
      const { ret, data, msg } = await GetPreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg || "获取预设参数失败!");
      }
      // 供水时间 water_supply_time_array
      data.water_supply_time_array.map((i) => {
        i.selected = false;
      });
      this.provideWaterTime = data.water_supply_time_array;
    },

    // 获取水箱
    async getWaterCaseList() {
      const params = {
        id_device: this.id_device,
      };
      const { ret, data, msg } = await GetWaterCase(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.options = data;
    },

    // 选中项发生变化
    selectedChange(item) {
      this.provideWaterTime.map((i) => {
        if (i.id !== item.id) {
          i.selected = false;
        }
      });
    },

    // 添加机组控制
    async addControl() {
      // 校验星期是否选择了
      let sum = 0;
      const arr = [0, 1, 2, 3, 4, 5, 6];
      arr.map((i) => {
        if (this.weeks["week" + (i + 1)]) {
          sum += Math.pow(2, i);
        }
      });
      if (sum === 0) {
        return this.$message.warning("请选择星期");
      }
      // 校验规则名称是否输入了
      const ruleName = this.ruleName.trim();
      if (!ruleName) {
        this.$message.warning("请输入规则名称");
        return;
      }

      // 供水时间
      const timeSelected = this.provideWaterTime.filter((i) => {
        return i.selected === true;
      });
      if (!timeSelected.length) {
        return this.$message.warning("请选择供水时间");
      }

      const reg = /^[1-9]\d{0,1}$/;
      // 水位 其中一个有值就校验
      if (this.index1 !== "" || this.level_value) {
        if (this.index1 === "") {
          return this.$message.warning("请选择水位端口");
        }
        if (!reg.test(this.level_value)) {
          return this.$message.warning("请检查水位输入");
        }
      }
      // 温度
      if (this.index2 !== "" || this.temp_value) {
        if (this.index2 === "") {
          return this.$message.warning("请选择温度端口");
        }
        if (!reg.test(this.temp_value)) {
          return this.$message.warning("请检查温度输入");
        }
      }

      const params = {
        id_channel_control: this.port.id,
        week_day: sum,
        remark: ruleName,
        detail_array: [],
      };
      // 供水时间
      const selected1 = this.provideWaterTime.findIndex((i) => i.selected);
      const current1 = this.provideWaterTime[selected1];
      params.time_start = current1.time_start;
      params.time_end = current1.time_end;

      const levelValue = parseInt(this.level_value);
      const tempValue = parseInt(this.temp_value);
      // 水位
      if (levelValue) {
        params.detail_array.push({
          id: this.index1,
          cfg_type: 1,
          preset: levelValue,
          target: levelValue,
        });
      }
      // 温度
      if (tempValue) {
        params.detail_array.push({
          id: this.index2,
          cfg_type: 0,
          preset: tempValue,
          target: tempValue,
        });
      }
      const { ret, data, msg } = await AddChannelTaskApi(params);
      if (ret !== 0) {
        return this.$message.error(msg || "添加供水泵控制计划失败!");
      }
      this.$message.success("添加供水泵控制计划成功");
      this.dialogVisible = false;
      const port = { ...this.port };
      port.show = false;
      this.$emit("refresh", port);
    },

    // 清除控制规则
    clearForm() {
      this.ruleName = "";
      this.index1 = "";
      this.index2 = "";
      this.level_value = "";
      this.temp_value = "";
      this.provideWaterTime = [];
      Object.keys(this.weeks).map((key) => {
        this.weeks[key] = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.week_box {
  text-align: center;
}

.rule_name {
  display: flex;
  justify-content: center;
  margin: 18px 0;

  .input_box {
    width: 300px;
    display: flex;
    align-items: center;

    .label {
      position: relative;
      width: 90px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      text-align: right;
      padding-right: 12px;

      &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        content: "*";
        color: red;
        font-size: 14px;
      }
    }

    input {
      flex: 1;
      height: 32px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      outline: none;
      box-sizing: border-box;
      padding-left: 12px;
    }
  }
}

.item_box {
  border: 1px solid #f0f3f8;
  border-radius: 4px;
  margin: 12px 0;

  .title {
    height: 48px;
    background: #f0f3f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;

    .left_box {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        margin: 0 12px 0 24px;
        transform: translateY(6px);
      }

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .star {
        color: #ff0000;
        margin-left: 4px;
        line-height: 1;
      }
    }
  }

  .content {
    padding: 0 32px;

    .select_box {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(n + 2) {
        border-top: 1px solid #eee;
      }

      .item {
        width: 400px;
        height: 42px;
        display: flex;
        align-items: center;

        span {
          margin-left: 36px;
        }
      }
    }

    .empty_data {
      width: 100%;
      height: 42px;
      line-height: 42px;
      text-align: center;
    }

    .ipt_box {
      width: 400px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .tip {
        width: 80px;
      }

      input {
        width: 120px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #bbb;
        background-color: #fff;
        outline: none;
        box-sizing: border-box;
        padding-left: 24px;
      }

      .unit {
        margin-left: 12px;
      }
    }
  }
}

.msg {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f8d071;
  margin-right: 24px;
}
</style>
